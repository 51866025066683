var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    attrs: {
      modalId: "requestPasswordResetModal",
      size: "md",
      showCloseButton: true,
      isStatic: true,
      startOpen: false,
    },
    on: { "hande:close": _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm.emailSend
              ? [
                  _vm._v(
                    "\n            Solicitação para redefinir sua senha recebida\n        "
                  ),
                ]
              : [_vm._v("\n            Esqueceu sua senha\n        ")],
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.emailSend
              ? [
                  _vm._v(
                    "\n            Enviamos um e-mail com as informações possíveis para redefinir sua senha. O e-mail pode levar alguns minutos para chegar à sua conta. Por favor, verifique seu lixo eletrônico para garantir que você receba.\n        "
                  ),
                ]
              : [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resetErr,
                          expression: "resetErr",
                        },
                      ],
                      staticClass: "alert alert-danger",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.resetErr) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "reset-password-form",
                      attrs: {
                        method: "POST",
                        name: "email-form",
                        id: "email-form",
                      },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.onSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "request-password-body" }, [
                        _c("p", [
                          _vm._v(
                            "\n                        Insira o endereço de e-mail da sua conta para receber um e-mail e redefinir sua senha.\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group required" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-control-label",
                              attrs: { for: "reset-password-email" },
                            },
                            [
                              _vm._v(
                                "\n                            E-mail\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email",
                              },
                            ],
                            staticClass: "form-control",
                            class: { error: _vm.errors.email },
                            attrs: {
                              id: "reset-password-email",
                              type: "email",
                              name: "loginEmail",
                              "aria-describedby": "emailHelp",
                              placeholder: "Digite seu e-mail",
                            },
                            domProps: { value: _vm.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.email = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.email,
                                  expression: "errors.email",
                                },
                              ],
                              staticClass: "invalid-feedback",
                              attrs: { role: "alert", id: "emailHelp" },
                            },
                            [_vm._v(_vm._s(_vm.errors.email))]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "send-email-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "login-option btn js-reset-password",
                            attrs: { type: "button", id: "submitEmailButton" },
                            on: {
                              click: function ($event) {
                                return _vm.resetPassword()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Enviar\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }