<template>
    <div class="formLogin-socials">
        <form action="" class="login-oauth" method="post" name="login-oauth-form">
            <a :href="GoogleUrl" class="oauth-google btn" role="button" aria-pressed="true">
                <img :src="iconGoogle" alt="connect with Google" />
                Entrar com Google
            </a>
        </form>
    </div>
</template>

<script>
    import routes from '../../../api/routes';

    export default {
        data() {
            return {
                staticUrl: routes.urlStatic,
                GoogleUrl: routes.login.oAuthLogin
            }
        },

        computed: {
            iconGoogle(){
                return this.staticUrl + '/login/iconGoogle.svg';
            }
        }
    }
</script>
