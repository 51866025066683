var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box_wrap box gallery-box" },
    [
      _c("image-gallery", { attrs: { images: _vm.imagesParsed } }),
      _vm._v(" "),
      _c("add-product-list", {
        attrs: {
          productId: _vm.productid,
          islogged: _vm.islogged,
          urllogin: _vm.urllogin,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }