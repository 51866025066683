<template>
    <div class="paymentOptionBox">
        <div class="payment-form-fields">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {}
</script>
