<template>
    <div class="handle-step__content" @click="$emit('handle:click')">
        <i style="transform: rotate(180deg);" class="icon-chevron-right"> </i>
        <span>Voltar</span>
    </div>
</template>

<script>
    export default {
        props: [],
    }
</script>