import routes from '../../api/routes'

const state = () => ({
    searchSuggestions: {}
})

const getters = {
    searchSuggestions: (state) => {
        return state.searchSuggestions
    },
}

const actions = {
    getStartSearch({ commit, dispatch, rootState }) {
        let url = `${routes.searchServices.getSuggestions}?getpopular=true`
        fetch(url)
        .then(response => response.json())
            .then(data => {
                commit('setData', data.suggestions)
            })
        .catch(console.error)
    },

    getSearchSuggestions({ commit, dispatch, rootState }, reqInfo) {
        let url = `${routes.searchServices.getSuggestions}?q=${reqInfo.searchTerm}`
        fetch(url)
        .then(response => response.json())
            .then(data => {
                commit('setData', data.suggestions)
            })
        .catch(console.error)
    },

    removeSearchSuggestions({ commit, dispatch, rootState }) {
        commit('setData', {})
    }
}

const mutations = {
    setData (state, data) {
        state.searchSuggestions = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}