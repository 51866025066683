var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedAddressIdModel,
            expression: "selectedAddressIdModel",
          },
        ],
        ref: "selectAddress",
        staticClass: "addressSelector form-control",
        attrs: { id: "billingAddressSelector", name: "addressSelector" },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedAddressIdModel = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.addressSelected,
          ],
        },
      },
      [
        _c(
          "option",
          { attrs: { disabled: "disabled" }, domProps: { value: false } },
          [_vm._v("\n            Selecione o endereço de cobrança\n        ")]
        ),
        _vm._v(" "),
        _vm.showNewAddress
          ? [
              _c("option", { attrs: { value: "new" } }, [
                _vm._v("\n                Novo endereço\n            "),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.order.shipping[0].shippingAddress
          ? [
              _c("option", { attrs: { disabled: "" } }, [
                _vm._v("- Remessas Existentes -"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.order.shipping, function (aShippingModel, index) {
                return [
                  aShippingModel.sellerID == "me"
                    ? _c(
                        "option",
                        { domProps: { value: aShippingModel.UUID } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                (aShippingModel.shippingAddress &&
                                  aShippingModel.shippingAddress.firstName) ||
                                  ""
                              ) +
                              " " +
                              _vm._s(
                                (aShippingModel.shippingAddress &&
                                  aShippingModel.shippingAddress.lastName) ||
                                  ""
                              ) +
                              " " +
                              _vm._s(
                                (aShippingModel.shippingAddress &&
                                  aShippingModel.shippingAddress.address1) ||
                                  ""
                              ) +
                              " " +
                              _vm._s(
                                (aShippingModel.shippingAddress &&
                                  aShippingModel.shippingAddress.city) ||
                                  ""
                              ) +
                              _vm._s(
                                (aShippingModel.shippingAddress &&
                                  !aShippingModel.shippingAddress.city) ||
                                  ","
                              ) +
                              " " +
                              _vm._s(
                                (aShippingModel.shippingAddress &&
                                  aShippingModel.shippingAddress.stateCode) ||
                                  ""
                              ) +
                              " " +
                              _vm._s(
                                (aShippingModel.shippingAddress &&
                                  aShippingModel.shippingAddress.postalCode) ||
                                  ""
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.customer.addresses && _vm.customer.addresses.length > 1
          ? [
              _c("option", { attrs: { disabled: "" } }, [
                _vm._v("- Catálogo de endereços -"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.customer.addresses, function (address) {
                return [
                  _c("option", { domProps: { value: address.ID } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(address.ID || "") +
                        " - " +
                        _vm._s((address && address.firstName) || "") +
                        " " +
                        _vm._s((address && address.lastName) || "") +
                        " " +
                        _vm._s((address && address.address1) || "") +
                        " " +
                        _vm._s((address && address.city) || "") +
                        _vm._s((address && !address.city) || ",") +
                        " " +
                        _vm._s((address && address.stateCode) || "") +
                        " " +
                        _vm._s((address && address.postalCode) || "") +
                        "\n                "
                    ),
                  ]),
                ]
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }