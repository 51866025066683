var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "formSearch",
      staticClass: "searchBar",
      attrs: {
        role: "search",
        action: _vm.formaction,
        method: "get",
        name: "simpleSearch",
        tabindex: "0",
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchInputModel,
            expression: "searchInputModel",
          },
        ],
        staticClass:
          "form-control searchBar__input search_field js-searchBar__input",
        attrs: {
          type: "text",
          name: "q",
          placeholder: _vm.placeholdertext,
          autocomplete: "off",
        },
        domProps: { value: _vm.searchInputModel },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.searchInputModel = $event.target.value
            },
            function ($event) {
              return _vm.onSearch()
            },
          ],
          click: _vm.onFocus,
        },
      }),
      _vm._v(" "),
      _vm.hasSearch(_vm.searchSuggestions)
        ? _c(
            "span",
            {
              staticClass: "search__input-close",
              on: { click: _vm.removeFocus },
            },
            [_vm._v("Fechar busca "), _c("span", [_vm._v("×")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "searchButton",
          attrs: {
            name: "search-button",
            "aria-label": "Buscar",
            disabled: _vm.disabled,
          },
          on: { click: _vm.submitForm },
        },
        [_c("i", { staticClass: "icon-search" })]
      ),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "lang" },
        domProps: { value: _vm.locale },
      }),
      _vm._v(" "),
      _vm.addOverflowHiddenToBody(_vm.searchSuggestions)
        ? _c("div", { staticClass: "searchSuggestions" }, [
            _c("div", { staticClass: "suggestions_modal" }, [
              _c(
                "div",
                { staticClass: "suggestions__container" },
                [
                  _vm.searchSuggestions.popular
                    ? [
                        _vm.searchSuggestions.popular &&
                        _vm.searchSuggestions.popular.available &&
                        _vm.searchSuggestions.popular.phrases.length
                          ? _c(
                              "div",
                              {
                                staticClass: "searchSuggestions__mostSearched",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "searchSuggestions__option--title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Termos mais buscados\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "searchSuggestions__terms" },
                                  _vm._l(
                                    _vm.searchSuggestions.popular.phrases,
                                    function (phrases, index) {
                                      return _c("li", { key: index }, [
                                        _c(
                                          "a",
                                          { attrs: { href: phrases.url } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "searchSuggestions__badge",
                                              },
                                              [_vm._v(_vm._s(index + 1))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "searchSuggestions__text",
                                              },
                                              [_vm._v(_vm._s(phrases.value))]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.searchSuggestions.einsteinRecommendations &&
                        _vm.searchSuggestions.einsteinRecommendations.length
                          ? _c(
                              "div",
                              { staticClass: "searchSuggestions__items" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "searchSuggestions__option--title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Você pode se interessar\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "searchSuggestions__tile" },
                                  [
                                    _vm._l(
                                      _vm.searchSuggestions
                                        .einsteinRecommendations,
                                      function (product) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "searchSuggestions__product",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: product.url,
                                                    "aria-label": product.name,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__product--image",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          alt:
                                                            "Imagem em miniatura do produto para" +
                                                            product.name,
                                                          src: product.imageUrl,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "searchSuggestions__product--content",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: product.url,
                                                        "aria-label":
                                                          product.name,
                                                      },
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(product.name)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__product--productPrice",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "searchSuggestions__product--label",
                                                        },
                                                        [_vm._v("a partir de")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "searchSuggestions__product--price",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.price
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__product--add js-search-addToCard",
                                                      attrs: {
                                                        "data-pid": product.id,
                                                        "data-url":
                                                          _vm.addtocart,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            +\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.searchSuggestions.category ||
                  _vm.searchSuggestions.product
                    ? [
                        (_vm.searchSuggestions.category &&
                          _vm.searchSuggestions.category.available) ||
                        (_vm.searchSuggestions.product &&
                          _vm.searchSuggestions.product.available &&
                          _vm.searchSuggestions.product.phrases.length)
                          ? _c(
                              "div",
                              {
                                staticClass: "searchSuggestions__mostSearched",
                              },
                              [
                                _vm.searchSuggestions.product &&
                                _vm.searchSuggestions.product.available &&
                                _vm.searchSuggestions.product.phrases.length
                                  ? [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "searchSuggestions__option--title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Você está procurando\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "searchSuggestions__terms terms_search",
                                        },
                                        _vm._l(
                                          _vm.searchSuggestions.product.phrases,
                                          function (phrases, index) {
                                            return _c("li", { key: index }, [
                                              _c(
                                                "a",
                                                {
                                                  attrs: { href: phrases.url },
                                                },
                                                [
                                                  _vm._m(0, true),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(phrases.value)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchSuggestions.category &&
                                _vm.searchSuggestions.category.available &&
                                _vm.searchSuggestions.category.categories.length
                                  ? [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "searchSuggestions__option--title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Buscar em\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "searchSuggestions__terms",
                                        },
                                        _vm._l(
                                          _vm.searchSuggestions.category
                                            .categories,
                                          function (categories, index) {
                                            return _c("li", { key: index }, [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: categories.url,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(categories.name)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.searchSuggestions.product &&
                        _vm.searchSuggestions.product.available &&
                        _vm.searchSuggestions.product.products.length
                          ? _c(
                              "div",
                              {
                                staticClass: "searchSuggestions__items",
                                class:
                                  this.searchSuggestions.category &&
                                  this.searchSuggestions.category.categories
                                    .length === 0 &&
                                  this.searchSuggestions.product &&
                                  this.searchSuggestions.product.phrases
                                    .length === 0
                                    ? "searchFullSuggestions__items"
                                    : "",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "searchSuggestions__option--title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Você pode se interessar\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "searchSuggestions__tile" },
                                  [
                                    _vm._l(
                                      _vm.searchSuggestions.product.products,
                                      function (product) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "searchSuggestions__product",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: product.url,
                                                    "aria-label": product.name,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__product--image",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          alt:
                                                            "Imagem em miniatura do produto para" +
                                                            product.name,
                                                          src: product.imageUrl,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "searchSuggestions__product--content",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: product.url,
                                                        "aria-label":
                                                          product.name,
                                                      },
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(product.name)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__product--productPrice",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "searchSuggestions__product--label",
                                                        },
                                                        [_vm._v("a partir de")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "searchSuggestions__product--price",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.price
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "searchSuggestions__product--add js-search-addToCard",
                                                      attrs: {
                                                        "data-pid": product.id,
                                                        "data-url":
                                                          _vm.addtocart,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            +\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "searchSuggestions__link",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitForm()
                                      },
                                    },
                                  },
                                  [_vm._v("Ver todos os produtos")]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "searchSuggestions__items" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "searchSuggestions__option--title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Nenhum produto encontrado\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "searchSuggestions__icon" }, [
      _c("i", { staticClass: "icon-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }