var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderComponent
    ? _c("div", { staticClass: "quantityAndShop" }, [
        _c("input", {
          staticClass: "add-to-cart-url",
          attrs: { type: "hidden" },
          domProps: { value: _vm.urlAddCart },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "quickBuyBtn add-to-cart-carousel",
            attrs: {
              "data-pid": _vm.id,
              id: `quickBuyBtn-${_vm.id}`,
              disabled: !_vm.productAvailable,
            },
          },
          [_vm._v("\n        Adicionar ao Carrinho\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "qtdContainer productCard",
            attrs: { id: `productCard-${_vm.id}` },
          },
          [
            _c(
              "div",
              { staticClass: "quantity-form-tile js-quantity-form-tile" },
              [
                _c(
                  "button",
                  {
                    staticClass: "decreaseAmountCart js-decreaseAmountCart",
                    attrs: { type: "button" },
                  },
                  [_vm._v(" - ")]
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "quantity",
                  attrs: {
                    type: "number",
                    id: `quantity-${_vm.uuid}`,
                    "data-uuid": _vm.uuidLineItem,
                    "data-pid": _vm.id,
                    "data-action": _vm.urlUpdateQnt,
                    "data-remove": _vm.urlRemoveProduct,
                    "data-pre-select-qty": _vm.quantity,
                    name: `quantity-${_vm.uuid}`,
                  },
                  domProps: { value: _vm.quantityFormat },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "increaseAmountCart js-increaseAmountCart",
                    attrs: { type: "button" },
                  },
                  [_vm._v(" + ")]
                ),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }