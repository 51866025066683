var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "tooltip-box",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleOpen.apply(null, arguments)
        },
      },
    },
    [
      _c("i", { staticClass: "icon-info" }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "tooltip-box-text",
          class: _vm.showTooltip ? "tooltip-show" : "",
        },
        [_vm._v("\n    " + _vm._s(_vm.msg) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }