<template>
  <div class="summary summary-mobile">

    <template v-if="showTitle === 'true'">
      <div class="summary__title">
        <h1>{{ productParsed.productName }}</h1>
        <small>(Código item: {{ productParsed.id }})</small>
      </div>
    </template>

    <template v-if="showTitle === 'false'">
      <div class="price">
          <span class="strike-through list">
            <template v-if="productParsed.promo">
              <!-- {{ productParsed.price.min ? productParsed.price.min.sales.formatted : productParsed.price.sales.formatted }} -->
              {{ productParsed.promo.defaultPriceFormatted }}
            </template>
          </span>
      </div>
      <div class="summary__price" v-if="productParsed.price">
        <p>A partir de:</p>
        <p class="price_box">
          <span v-if="productParsed.promo" class="price">{{ productParsed.promo.promotionPriceFormatted }}</span>
          <span v-else class="price">{{ productParsed.price.min ? productParsed.price.min.sales.formatted : productParsed.price.sales.formatted }}</span>

        <span v-if="productParsed.promo" class="promotion_tag">{{productParsed.promo.promotionPercentage}}% OFF</span>
        </p>
      </div>

      <template v-if="productParsed.available">
        <product-quantity
          use="PDP"
          :showQnt="true"
          :id="productParsed.id"
          :uuid="productParsed.uuid"
          :quantity="productParsed.quantity"
          :uuidLineItem="productParsed.uuidLineItem"
          :productAvailable="productParsed.available"
          :urlAddCart="urlAddCart"
          :urlUpdateQnt="urlUpdateQnt"
          :urlRemoveProduct="urlRemoveProduct"
        >
        </product-quantity>
      </template>
      <template v-else>
        <p class="summary__quantity__unavailable">Produto Indisponível</p>
      </template>

      <div class="summary__description">
        <b class="summary_title_description">Descrição do produto</b>
        <div v-html="productParsed.shortDescription"></div>
      </div>
    </template>

  </div>
</template>

<script>
import ProductQuantity from './ProductQuantity';

export default {
  props: ['product', 'showTitle', 'urlAddCart', 'urlUpdateQnt', 'urlRemoveProduct'],
  components: {
    ProductQuantity
  },
  created() {
      this.productParsed = JSON.parse(this.product);
  },
  data() {
    return {
      isFixed: false,
      initialOffsetTop: 0,
      initialWidth: null,
      productParsed: null,
    };
  },
  mounted() {
    if (window.innerWidth >= 769) {
      this.$nextTick(() => {
        if (this.$refs.summaryBox) {
          const rect = this.$refs.summaryBox.getBoundingClientRect();
          this.initialOffsetTop = rect.top + window.pageYOffset;
          this.initialWidth = rect.width;
          window.addEventListener('scroll', this.handleScroll);
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop >= this.initialOffsetTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
};
</script>


<style>
  .summary_title_description{
    margin-bottom: 10px;
    display: inline-block;
  }

  .summary__quantity__unavailable {
    position: relative;
    top: 0;
    margin-top: 0;
    float: right;
    padding: 10px;
    border-radius: 5px;
    background: #efefef;
  }

  .summary-mobile{
    .summary__title h1{
      font-size: 20px;
    }
    .summary__price{
      p{
        margin-bottom: 5px;
      }

    }

    .price_box span{
      font-size: 35px;
      font-weight: bold;
    }

    .summary__description{
      margin-top: 30px;
      color: #696969;
    }
  }
</style>