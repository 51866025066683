<template>
  <div class="box_wrap">
    <p class="title">Informações adicionais</p>
    <div class="long-description box" v-html="longDescription">
    </div>
  </div>
</template>

<script>
export default {
  props: ['longDescription'],
}
</script>

<style>
  @media (max-width: 992px) {
    .long-description {
      padding: 0px !important;
      border-radius: 0px !important;
      border: none !important;
      background: none !important;
    }
  }
</style>
