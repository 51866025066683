var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quantityAndShop" }, [
    _c("input", {
      staticClass: "add-to-cart-url",
      attrs: { type: "hidden" },
      domProps: { value: _vm.urlAddCart },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "quickBuyBtn add-to-cart-carousel js-pdp-add",
        attrs: {
          "data-pid": _vm.id,
          "data-pidsobj": _vm.idObj,
          id: `quickBuyBtn-${_vm.id}`,
          disabled: !_vm.productAvailable,
        },
      },
      [_vm._v("\n        Adicionar ao Carrinho\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }