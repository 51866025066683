var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box_wrap" }, [
    _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "product-datasheet-box" }, [
      _c(
        "table",
        { staticClass: "product-datasheet" },
        _vm._l(_vm.datasheetMock, function (attr, index) {
          return _c("tr", { key: index }, [
            _c("th", [_vm._v(_vm._s(attr.attr))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(attr.value))]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }