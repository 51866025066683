var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "pdp-main" } },
    [
      _c("breadcrumb", { attrs: { breadcrumbs: _vm.pData.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-6" },
          [
            _c("long-description", {
              attrs: { "long-description": _vm.pData.product.longDescription },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-sm-6" },
          [_c("product-summary", { attrs: { product: _vm.pData.product } })],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }