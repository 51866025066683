var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "pdp-carousel d-none d-lg-block" },
      [
        _c(
          "slick",
          {
            ref: "thumbnail",
            staticClass: "thumbnailCarousel",
            attrs: { options: _vm.thumbnailOptions },
          },
          _vm._l(_vm.images.images.small, function (image, index) {
            return _c("img", {
              key: image.index,
              staticClass: "img-pdp-thumbnailCarousel",
              attrs: {
                src: image.absURL,
                alt: image.alt,
                loading: "lazy",
                onerror: "this.src='" + _vm.urlNoImg + "'",
              },
            })
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "slick",
          {
            ref: "main",
            staticClass: "mainCarousel pdpSlider",
            attrs: { options: _vm.mainOptions },
          },
          _vm._l(_vm.images.images.large, function (image, index) {
            return _c("img", {
              key: image.index,
              attrs: {
                src: image.absURL,
                alt: image.alt,
                loading: "lazy",
                "data-url": "hello",
                onerror: "this.src='" + _vm.urlNoImg + "'",
              },
            })
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pdp-carousel pdp-mobile-carousel d-lg-none" },
      [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "slick",
              {
                ref: "main",
                staticClass: "mainMobileCarousel pdpSlider",
                attrs: { options: _vm.mainMobileOptions },
              },
              _vm._l(_vm.images.images.large, function (image, index) {
                return _c("img", {
                  key: image.index,
                  attrs: { src: image.absURL, alt: image.alt, loading: "lazy" },
                })
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "slick",
          {
            ref: "thumbnail",
            staticClass: "thumbnailMobileCarousel",
            attrs: { options: _vm.thumbnailMobileOptions },
          },
          _vm._l(_vm.images.images.small, function (image, index) {
            return _c("img", {
              key: image.index,
              attrs: { src: image.absURL, alt: image.alt, loading: "lazy" },
            })
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }