<template>
    <div class="quotationOptions">
        <!-- sellersWithAllProducts -->
        <template v-if="quotationModel && quotationModel.sellersWithAllProducts">
            <div v-for="(quotation) in quotationModel.sellersWithAllProducts">
                <div class="quotation-body__container" :class="{'selected' : quotation.id === selectedQuotationId}"
                    @click.once="handleQuotationSelect(quotation)"
                >
                    <!-- quotation body -->
                    <quotation-body
                        :quotation="quotation"
                        :quotationModel="quotationModel"
                        type="seller"
                        @handle:quotation="toggleQuotationDetails(quotation, 'seller')"
                    />

                    <!-- modal details -->
                    <quotation-modal-details
                        v-if="quotation.id === selectedQuotationId && showModal"
                        :quotationDetails="selectedQuotationDetails"
                        :type="type"
                        :showModal="showModal"
                        @closeModal="closeModal"
                    />
                </div>
            </div>
        </template>

        <!-- multisellers -->
        <template v-if="quotationModel.multisellers && quotationModel.multisellers.sellers">
            <div  v-for="(quotation) in quotationModel.multisellers.sellers">
                <div class="quotation-body__container" :class="{'selected' : quotation.id === selectedQuotationId}"
                    @click="handleQuotationClick(quotation, event)"
                >
                    <!-- quotation body -->
                    <quotation-body
                        :quotation="quotation"
                        :quotationModel="quotationModel"
                        type="multiseller"
                        @handle:quotation="toggleQuotationDetails(quotationModel.multisellers, 'multiseller')"
                    />

                    <quotation-modal-details
                        v-if="quotation.id === selectedQuotationId && showModal"
                        :quotationDetails="selectedQuotationDetails"
                        :type="type"
                        :showModal="showModal"
                        @closeModal="closeModal"
                    />
                </div>
            </div>
        </template>

        <!-- sellersWithoutAllProducts -->
        <template v-if="quotationModel && quotationModel.sellersWithoutAllProducts">
            <div v-for="(quotation) in quotationModel.sellersWithoutAllProducts">
                <div class="quotation-body__container" :class="{'selected' : quotation.id === selectedQuotationId}"
                    @click.once="handleQuotationSelect(quotation)"
                >
                    <!-- quotation body -->
                    <quotation-body
                        :quotation="quotation"
                        :quotationModel="quotationModel"
                        type="seller"
                        @handle:quotation="toggleQuotationDetails(quotation, 'seller')"
                    />

                    <!-- modal details -->
                    <quotation-modal-details v-if="quotation.id === selectedQuotationId && showModal"
                        :quotationDetails="selectedQuotationDetails" :type="type" :showModal="showModal"
                        @closeModal="closeModal" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import * as $ from "jquery";
import routes from '../../../api/routes';
import QuotationModalDetails from './QuotationModalDetails.vue';
import QuotationBody from "./QuotationBody.vue";

export default {
    props: ['quotationModel'],
    components: {
        QuotationModalDetails,
        QuotationBody,
    },
    data() {
        return {
            staticUrl: routes.urlStatic,
            selectedQuotationDetails: null,
            isHovering: false,
            type: null,
            showModal: false,
            selectedQuotationId: null,
        }
    },
    methods: {
        async quotationSelect(quotation, sellerObj, event = null) {

            if (event) {
                var eventParent = event.target.parentElement;
                var eventGrandParent = eventParent.parentElement;

                if (eventGrandParent.classList.contains('slick-dots')) {
                    return;
                }
            }
            $('.quotationOptions').addClass('is__loading')
            this.closeModal()

            this.$store.commit('checkout/setQuotationObj', sellerObj)
            this.$store.commit('checkout/setQuotationSelected', quotation)

            const resultSetQuotation = await this.$store.dispatch('checkout/quotationSelect', sellerObj);

            if (resultSetQuotation.success) {
                this.$root.$refs.Checkout.nextStep();
            } else {
                window.location = resultSetQuotation.redirectUrl;
            }
        },
        sellerProducts(sellerItems) {
            var sellerProducts = sellerItems.map((item) => item.id);
            var encodeProductsBase64 = btoa(JSON.stringify(sellerProducts));
            console.log(encodeProductsBase64);
            return encodeProductsBase64;
        },
        handleQuotationSelect(quotation) {
            const sellerObj = {
                'seller_1': quotation.id + '|' + this.sellerProducts(quotation.items.items) + '|' + quotation.shippingDate + '|' + quotation.totals.totalShippingCost.value + '|' + quotation.shippingDeliveryDateTicks
            };
            this.quotationSelect(quotation, sellerObj);
        },
        handleQuotationClick(quotation, event) {
            this.quotationSelect(
                this.quotationModel.multisellers,
                quotation
                    ? quotation.sellers.reduce(
                          (obj, seller, index) => ({
                              ...obj,
                              ['seller_' + (index + 1)]:
                                  seller.id +
                                  '|' +
                                  this.sellerProducts(seller.items) +
                                  '|' +
                                  seller.shippingDate +
                                  '|' +
                                  seller.totals.shippingTotalPriceValue +
                                  '|' +
                                  seller.shippingDeliveryDateTicks,
                          }),
                          {}
                      )
                    : {},
                event
            );
        },
        toggleQuotationDetails(quotation, type) {
            if (this.selectedQuotationId === quotation.id) {
                this.closeModal();
            } else {
                this.selectedQuotationDetails = quotation;
                this.selectedQuotationId = quotation.id;
                this.showModal = true;
                this.type = type;
            }
        },
        closeModal() {
                this.showModal = false;
                this.selectedQuotationId = null;
        },
        setHover(index, value) {
            this.$set(this.isHovering, index, value);
        },
        toggleHovering() {
            this.isHovering = !this.isHovering;
        }
    },
}
</script>
