var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.parsedItems.length
    ? _c(
        "div",
        { staticClass: "box_wrap" },
        [
          _vm.title
            ? _c("p", { staticClass: "title d-none d-lg-block" }, [
                _vm._v(_vm._s(_vm.title)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.titleMobile
            ? _c("p", { staticClass: "title d-lg-none" }, [
                _vm._v(_vm._s(_vm.titleMobile)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "slick",
            {
              ref: "main",
              staticClass: "mainItensCarousel pdpSlider",
              attrs: { options: _vm.mainItensCarousel },
            },
            _vm._l(_vm.parsedItems, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "slide" },
                [
                  _c("product-card", {
                    attrs: {
                      product: item,
                      showQnt: false,
                      showBtn: true,
                      urlAddCart: _vm.urlAddCart,
                      urlUpdateQnt: _vm.urlUpdateQnt,
                      urlRemoveProduct: _vm.urlRemoveProduct,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }