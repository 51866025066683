var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3 paymentBox" }, [
    _c(
      "div",
      { staticClass: "paymentBox__content" },
      [
        _vm.innerStep === 1
          ? [
              _c("h2", [_vm._v("FORMAS DE PAGAMENTO")]),
              _vm._v(" "),
              _c("coupon"),
              _vm._v(" "),
              _c("payment-options", {
                attrs: {
                  paymentMethodModel: _vm.paymentMethodModel,
                  creditCardModel: _vm.creditCardModel,
                  errorsCreditCard: _vm.formErrorsCC,
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }