var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "removeCouponModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "removeCouponLineItemModal",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body delete-coupon-confirmation-body" },
            [
              _vm._v(
                "\n                Tem certeza que deseja remover o seguinte cupom do carrinho?\n                "
              ),
              _c("p", { staticClass: "coupon-to-remove" }, [
                _vm._v(_vm._s(_vm.couponCode)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v("Cancelar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary delete-coupon-confirmation-modal-btn",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("remove-coupon", _vm.uuid)
                  },
                },
              },
              [_vm._v("\n                        Sim\n                ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal-header delete-coupon-confirmation-header" },
      [
        _c(
          "h2",
          {
            staticClass: "modal-title",
            attrs: { id: "removeCouponLineItemModal" },
          },
          [_vm._v("Remover cupom?")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }