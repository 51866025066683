var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quotationOptions" }, [
    _vm.quotationSelected
      ? _c("div", [
          _c("div", { staticClass: "quotation-body__container" }, [
            _c(
              "div",
              { staticClass: "quotationBody" },
              [
                _vm.quotationType === "seller"
                  ? [
                      _vm.quotationSelected.flag
                        ? _c(
                            "div",
                            {
                              staticClass: "quotation__flag",
                              class: _vm.quotationSelected.flag.id,
                            },
                            [
                              _c("img", {
                                attrs: {
                                  loading: "lazy",
                                  src: _vm.quotationSelected.flag.image.url,
                                  alt: _vm.quotationSelected.flag.image.alt,
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.quotationSelected.flag.name) + " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : [
                      _vm.quotationSelected.flag
                        ? _c(
                            "div",
                            {
                              staticClass: "quotation__flag",
                              class: _vm.quotationSelected.flag.id,
                            },
                            [
                              _c("img", {
                                attrs: {
                                  loading: "lazy",
                                  src: _vm.quotationSelected.flag.image.url,
                                  alt: _vm.quotationSelected.flag.image.alt,
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.quotationSelected.flag.name) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _vm.showModal
              ? _c("div", { staticClass: "expanded-modal" }, [
                  _c(
                    "div",
                    { staticClass: "expanded-modal-content" },
                    [
                      _vm.quotationType === "multiseller"
                        ? [
                            _c(
                              "div",
                              { staticClass: "modal-body" },
                              _vm._l(
                                _vm.quotationSelected.sellers[0].sellers,
                                function (seller) {
                                  return _c(
                                    "div",
                                    { staticClass: "productList-container" },
                                    [
                                      _vm._l(
                                        seller.shipments,
                                        function (shipment) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "header-title__store",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "store-name__title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        shipment.sellerName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "productList-table-header",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center start",
                                                  },
                                                  [_vm._v("Produtos")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center d-none d-lg-flex",
                                                  },
                                                  [_vm._v("Quantidade")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center d-lg-none",
                                                  },
                                                  [_vm._v("Qtd")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [_vm._v("Preço")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center d-none d-lg-flex",
                                                  },
                                                  [_vm._v("Entrega")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center minWidth",
                                                  },
                                                  [_vm._v(" Total ")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  gap: "12px",
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                },
                                              },
                                              _vm._l(
                                                seller.items,
                                                function (product) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "productList-table",
                                                    },
                                                    [
                                                      product.images
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "productDetail",
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "img-fluid",
                                                                attrs: {
                                                                  src: product
                                                                    .images
                                                                    .small[0]
                                                                    .url,
                                                                  alt: product
                                                                    .images
                                                                    .small[0]
                                                                    .alt,
                                                                  title:
                                                                    product
                                                                      .images
                                                                      .small[0]
                                                                      .title,
                                                                  onerror:
                                                                    "this.src='" +
                                                                    _vm.urlNoImg +
                                                                    "'",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "product-name__table",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      product.productName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.quantity
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.price
                                                                .sales.formatted
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-center d-none d-lg-flex",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              shipment.deliveryDateFormatted
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-center minWidth",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.priceTotal
                                                                .price
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        : [
                            _c("div", { staticClass: "modal-body" }, [
                              _c(
                                "div",
                                { staticClass: "productList-container" },
                                [
                                  _vm._l(
                                    _vm.quotationSelected.shipments,
                                    function (shipment) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "header-title__store",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "store-name__title",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(shipment.sellerName)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "productList-table-header",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center start",
                                              },
                                              [_vm._v("Produtos")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center d-none d-lg-flex",
                                              },
                                              [_vm._v("Quantidade")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center d-lg-none",
                                              },
                                              [_vm._v("Qtd")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "text-center" },
                                              [_vm._v("Preço")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center d-none d-lg-flex",
                                              },
                                              [_vm._v("Entrega")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center minWidth",
                                              },
                                              [_vm._v(" Total ")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              gap: "12px",
                                              display: "flex",
                                              "flex-direction": "column",
                                            },
                                          },
                                          _vm._l(
                                            _vm.quotationSelected.items.items,
                                            function (product) {
                                              return _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "productList-table",
                                                },
                                                [
                                                  product.images
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "productDetail",
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-fluid",
                                                            attrs: {
                                                              src: product
                                                                .images.small[0]
                                                                .url,
                                                              alt: product
                                                                .images.small[0]
                                                                .alt,
                                                              title:
                                                                product.images
                                                                  .small[0]
                                                                  .title,
                                                              onerror:
                                                                "this.src='" +
                                                                _vm.urlNoImg +
                                                                "'",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-name__table",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  product.productName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(product.quantity)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.price.sales
                                                            .formatted
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center d-none d-lg-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          shipment.deliveryDateFormatted
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center minWidth",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.priceTotal
                                                            .price
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }