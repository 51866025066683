<template>
  <div class="box_wrap" v-show="itemsParsed.value != false">
    <p class="title d-none d-lg-block">{{ title }}</p>
    <p class="title d-lg-none">{{ titleMobile }}</p>

    <div class="pdp-buy-together">
      <div class="pdp-buy-together__items">
        <product-card
          :product="productParsed"
          :showBtn="false"
        />
        <div class="pdp-buy-together__icon-plus">
          <i class="icon-icon-more"></i>
        </div>
        <product-card
          :product="itemsParsed.product"
          :showBtn="false"
        />
      </div>

    <div class="pdp-buy-together-box">
        <span class="price"><b>Preço total:</b> {{ itemsParsed.value }}</span>

        <product-add
          :pidsobj="idObj"
          :productAvailable="productParsed.available"
          :urlAddCart="urlAddCart"
          :urlUpdateQnt="urlUpdateQnt"
          :urlRemoveProduct="urlRemoveProduct"
          use="PDP">
        </product-add>
        <div class="pdp-alert">
          <i class="icon-info-circle-fill"></i>
          <p>Estes itens poderão ser enviados e vendidos por vendedores diferentes.</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import ProductCard from './ProductCard';
import ProductAdd from './ProductAdd';
export default {
  props: ['title', 'titleMobile', 'product', 'productBuyTogether', 'urlAddCart', 'urlUpdateQnt', 'urlRemoveProduct'],
  components: {
    ProductCard,
    ProductAdd
  },
  created() {
    this.itemsParsed = JSON.parse(this.productBuyTogether);
    this.productParsed = JSON.parse(this.product);
    this.idObj = [{
                    pid: this.productParsed.id,
                    qty: 1
                  },
                  {
                    pid: this.itemsParsed.product.id,
                    qty: 1
                  }];
  },
  data() {
    return {
      productParsed: null,
      itemsParsed: null,
      idObj: null,
    };
  }
}
</script>

<style>
.pdp-buy-together {
  display: flex;
  gap: 10px;
  max-height: 310px;
  flex-direction: row;

  @media (max-width: 992px) {
    flex-direction: column;
    max-height: 610px;
  }

  .productTileBox {
    margin-top: 0px !important;
  }

  .product-tile-pd {
    max-height: 310px !important;
  }

  .productTileBox .product-tile-body {
    min-height: 80px !important;
  }

  .quantityAndShop{
    margin-top: 0px;
  }

}

.pdp-buy-together__items {
  display: flex;
  gap: 10px;
  flex-direction: row;

  @media (max-width: 992px) {
    .product-tile-pd {
      margin-right: 0px !important;
    }
  }
  @media (max-width: 576px) {
    gap: 2.5px;
  }
}

.pdp-buy-together__icon-plus {
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 992px) {
    font-size: 25px;
  }
}

.pdp-buy-together-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 220px;
  gap: 16px;
}

.pdp-buy-together-box .price {
  font-size: 14px;
}

.pdp-alert {
  display: flex;
  gap: 10px;

  i {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }
}
</style>
