var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resumeProducts-overflow" },
    _vm._l(_vm.order.shipping, function (shippingModel) {
      return _c("div", { staticClass: "sellerOrderDetail--container" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-direction": "column" } },
          [
            shippingModel.store && shippingModel.store.sellerName
              ? _c("span", [
                  _vm._v("Vendido por: "),
                  _c("b", { staticClass: "sellerName" }, [
                    _vm._v(_vm._s(shippingModel.store.sellerName)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            shippingModel.store && shippingModel.store.sellerName
              ? _c("span", [
                  _vm._v("Entrega: "),
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(shippingModel.sellerEstimatedArrivalDate) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "lineItems__container",
            staticStyle: { display: "flex", "flex-direction": "column" },
          },
          _vm._l(shippingModel.items, function (lineItem) {
            return _c(
              "div",
              {
                staticClass:
                  "resumeProductCard sellerOrderDetailProduct--container",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "resumeImgBox" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: {
                        src: lineItem.images.small[0].url,
                        alt: lineItem.images.small[0].alt,
                        title: lineItem.images.small[0].title,
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "nameAndQtt" }, [
                    _c("h3", { staticClass: "resumeProductName" }, [
                      _vm._v(_vm._s(lineItem.productName)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "resumeQttItem" }, [
                      _vm._v(" Quantidade: " + _vm._s(lineItem.quantity)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "h4",
                      {
                        staticClass: "resumeQttItem",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [_vm._v(" " + _vm._s(lineItem.price.sales.formatted))]
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }