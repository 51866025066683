var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stepMenuContainer" }, [
    _c(
      "div",
      {
        staticClass: "stepMenu cotacao",
        class: { active: _vm.validateStep(1), current: _vm.activeStep === 1 },
      },
      [
        _c("span", [_vm._v("1")]),
        _vm._v(" "),
        _c("p", { staticClass: "stepIndicatorName" }, [_vm._v("COTAÇÃO")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "stepMenu shipping",
        class: { active: _vm.validateStep(2), current: _vm.activeStep === 2 },
      },
      [
        _c("span", [_vm._v("2")]),
        _vm._v(" "),
        _c("p", { staticClass: "stepIndicatorName" }, [_vm._v("ENTREGA")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "stepMenu payment",
        class: { active: _vm.validateStep(3), current: _vm.activeStep === 3 },
      },
      [
        _c("span", [_vm._v("3")]),
        _vm._v(" "),
        _c("p", { staticClass: "stepIndicatorName" }, [_vm._v("PAGAMENTO")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }