<template>
   <form
        class="d-flex flex-column justify-content-center form-container"
        id="billing-form"
        :id="forms.billingForm.addressFields.dynamicHtmlName"
        :name="forms.billingForm.addressFields.dynamicHtmlName"
    >
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.firstName.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.firstName.htmlName]">
                    <label class="form-control-label" for="billingFirstName"></label>
                    <input
                        autofocus
                        type="text"
                        autocomplete="given-name"
                        id="billingFirstName"
                        :required="forms.billingForm.addressFields.firstName.mandatory === true ? true : false"
                        placeholder="Nome"
                        class="checkoutInputs form-control billingFirstName"
                        :class="{'error': errors.dwfrm_billing_addressFields_firstName}"
                        v-model="addressModel[forms.billingForm.addressFields.firstName.htmlName]"
                        :pattern="forms.billingForm.addressFields.firstName.regEx"
                        :maxlength="forms.billingForm.addressFields.firstName.maxLength"
                        :minLength="forms.billingForm.addressFields.firstName.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_firstName" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_firstName }}
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.lastName.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.lastName.htmlName]">
                    <label class="form-control-label" for="billingLastName"></label>
                    <input
                        type="text"
                        autocomplete="family-name"
                        id="billingLastName"
                        :required="forms.billingForm.addressFields.lastName.mandatory === true ? true : false"
                        placeholder="Sobrenome"
                        class="checkoutInputs form-control billingLastName"
                        :class="{'error': errors.dwfrm_billing_addressFields_lastName}"
                        v-model="addressModel[forms.billingForm.addressFields.lastName.htmlName]"
                        :pattern="forms.billingForm.addressFields.lastName.regEx"
                        :maxlength="forms.billingForm.addressFields.lastName.maxLength"
                        :minLength="forms.billingForm.addressFields.lastName.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_lastName" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_lastName}}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.postalCode.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.postalCode.htmlName]">
                    <label class="form-control-label" for="billingZipCode"></label>
                    <input
                        type="text"
                        autocomplete="billing postal-code"
                        id="billingZipCode"
                        :required="forms.billingForm.addressFields.postalCode.mandatory === true ? true : false"
                        placeholder="Código Postal"
                        class="checkoutInputs form-control billingZipCode zipCodeChekout"
                        :class="{'error': errors.dwfrm_billing_addressFields_postalCode}"
                        v-on:input="handleZipCode"
                        v-mask="'#####-###'"
                        v-model="addressModel[forms.billingForm.addressFields.postalCode.htmlName]"
                        :pattern="forms.billingForm.addressFields.postalCode.regEx"
                        :maxlength="forms.billingForm.addressFields.postalCode.maxLength"
                        :minLength="forms.billingForm.addressFields.postalCode.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_postalCode" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_postalCode }}
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.at_address_number.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.at_address_number.htmlName]">
                    <label class="form-control-label" for="billingAddressTwo"></label>
                    <input
                        type="text"
                        autocomplete="billing address-line2"
                        id="billingAddressTwo"
                        :required="forms.billingForm.addressFields.at_address_number.mandatory === true ? true : false"
                        placeholder="Número"
                        class="checkoutInputs form-control billingAddressTwo"
                        :class="{'error': errors.dwfrm_billing_addressFields_at__address__number}"
                        v-model="addressModel[forms.billingForm.addressFields.at_address_number.htmlName]"
                        :pattern="forms.billingForm.addressFields.at_address_number.regEx"
                        :maxlength="forms.billingForm.addressFields.at_address_number.maxLength"
                        :minLength="forms.billingForm.addressFields.at_address_number.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_at__address__number" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_at__address__number }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.address1.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.address1.htmlName]">
                    <label class="form-control-label" for="billingAddressOne"></label>
                    <input
                        type="text"
                        autocomplete="billing address-line1"
                        id="billingAddressOne"
                        :required="forms.billingForm.addressFields.address1.mandatory === true ? true : false"
                        placeholder="Endereço"
                        class="checkoutInputs form-control billingAddressOne addressOneChekout"
                        :class="{'error': errors.dwfrm_billing_addressFields_address1}"
                        v-model="addressModel[forms.billingForm.addressFields.address1.htmlName]"
                        :pattern="forms.billingForm.addressFields.address1.regEx"
                        :maxlength="forms.billingForm.addressFields.address1.maxLength"
                        :minLength="forms.billingForm.addressFields.address1.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_address1" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_address1 }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-5">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.address2.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.address2.htmlName]">
                    <label class="form-control-label" for="billingAddressTwo"></label>
                    <input
                        type="text"
                        autocomplete="billing address-line2"
                        id="billingAddressTwo"
                        :required="forms.billingForm.addressFields.address2.mandatory === true ? true : false"
                        placeholder="Complemento"
                        class="checkoutInputs form-control billingAddressTwo"
                        :class="{'error': errors.dwfrm_billing_addressFields_address2}"
                        v-model="addressModel[forms.billingForm.addressFields.address2.htmlName]"
                        :pattern="forms.billingForm.addressFields.address2.regEx"
                        :maxlength="forms.billingForm.addressFields.address2.maxLength"
                        :minLength="forms.billingForm.addressFields.address2.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_address2" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_address2 }}
                    </div>
                </div>
            </div>

            <div class="col-sm-7">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.at_address_district.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.at_address_district.htmlName]">
                    <label class="form-control-label" for="billingNeighborhood"></label>
                    <input
                        type="text"
                        autocomplete="shipping address-line2"
                        id="billingNeighborhood"
                        :required="forms.billingForm.addressFields.at_address_district.mandatory === true ? true : false"
                        placeholder="Bairro"
                        class="checkoutInputs form-control billingNeighborhood addressDistrictChekout"
                        :class="{'error': errors.dwfrm_billing_addressFields_at__address__district}"
                        v-model="addressModel[forms.billingForm.addressFields.at_address_district.htmlName]"
                        :pattern="forms.billingForm.addressFields.at_address_district.regEx"
                        :maxlength="forms.billingForm.addressFields.at_address_district.maxLength"
                        :minLength="forms.billingForm.addressFields.at_address_district.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_at__address__district" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_at__address__district }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-9">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.city.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.city.htmlName]">
                    <label class="form-control-label" for="billingAddressCity"></label>
                    <input
                        type="text"
                        autocomplete="billing address-level2"
                        id="billingAddressCity"
                        :required="forms.billingForm.addressFields.city.mandatory === true ? true : false"
                        placeholder="Cidade" 
                        class="checkoutInputs form-control billingAddressCity addressCityeChekout"
                        :class="{'error': errors.dwfrm_billing_addressFields_city}"
                        v-model="addressModel[forms.billingForm.addressFields.city.htmlName]"
                        :pattern="forms.billingForm.addressFields.city.regEx"
                        :maxlength="forms.billingForm.addressFields.city.maxLength"
                        :minLength="forms.billingForm.addressFields.city.minLength">
                    <div v-show="errors.dwfrm_billing_addressFields_city" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_city }}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-group"
                    :class="[forms.billingForm.addressFields.states.stateCode.mandatory === true ? 'required' : '',
                        forms.billingForm.addressFields.states.stateCode.htmlName]">
                    <label class="form-control-label" for="billingState">Estado</label>
                    <select
                        :required="forms.billingForm.addressFields.states.stateCode.mandatory === true ? true : false"
                        class="checkoutInputs form-control billingState custom-select"
                        id="billingState"
                        v-model="addressModel[forms.billingForm.addressFields.states.stateCode.htmlName]"
                        autocomplete="billing address-level1"
                        :class="{'error': errors.dwfrm_billing_addressFields_states_stateCode}"
                    >
                        <option value="" disabled selected hidden>UF</option>
                        <option
                            v-for="(state, index) in forms.billingForm.addressFields.states.stateCode.options"
                            :key="index"
                            :id="state.id"
                            :value="state.htmlValue"
                            :selected="state.selected"
                        >
                        {{ state.htmlValue }}
                        </option>
                    </select>
                    <div v-show="errors.dwfrm_billing_addressFields_states_stateCode" class="error-message">
                        {{ errors.dwfrm_billing_addressFields_states_stateCode }}
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {

        computed: {
            ...mapGetters('checkout', {
                forms: "forms",
            }),
        },

        props: {
            addressModel: {
                default: {},
                type: Object
            },
            errors: {
                default: {},
                type: Object
            }
        },

        methods: {
            handleZipCode(){
                var url = 'https://viacep.com.br/ws/' + $('#billingZipCode').val() + '/json/';
                this.cleanInput()
                if (this.addressModel['dwfrm_billing_addressFields_postalCode'].length === 9) {
                    fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        if (!data.erro) {
                            $('#billingZipCode')
                                .removeClass('is-invalid')
                                .siblings('.error-message')
                                .html('');
                            this.addressModel['dwfrm_billing_addressFields_address1'] = data.logradouro
                            this.addressModel['dwfrm_billing_addressFields_at__address__district'] = data.bairro
                            this.addressModel['dwfrm_billing_addressFields_city'] = data.localidade
                            this.addressModel['dwfrm_billing_addressFields_states_stateCode'] = data.uf
                        } else {
                            $('#billingZipCode')
                                .addClass('is-invalid')
                                .siblings('.error-message')
                                .html('CEP inválido');
                        }
                    })
                }
            },

            cleanInput(){
                this.addressModel['dwfrm_billing_addressFields_address1'] = ''
                this.addressModel['dwfrm_billing_addressFields_at__address__district'] = ''
                this.addressModel['dwfrm_billing_addressFields_city'] = ''
                this.addressModel['dwfrm_billing_addressFields_states_stateCode'] = ''
            },
        }
    }
</script>
