var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3 shippingBox" }, [
    _c("div", { staticClass: "shippingBox__content" }, [
      _c("h2", [_vm._v("DADOS DE ENTREGA")]),
      _vm._v(" "),
      _c("div", { staticClass: "row cpfCnpjMargin" }, [
        _c(
          "div",
          {
            class: _vm.isMobile
              ? _vm.changeFlag
                ? "col-9"
                : "col-12"
              : _vm.changeFlag
              ? "col-10"
              : "col-12",
          },
          [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: [
                  _vm.forms.billingForm.contactInfoFields.cpfCnpj.mandatory ===
                  true
                    ? "required"
                    : "",
                  _vm.forms.billingForm.contactInfoFields.cpfCnpj.htmlName,
                ],
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.contactInfoModel[
                          _vm.forms.billingForm.contactInfoFields.cpfCnpj
                            .htmlName
                        ],
                      expression:
                        "contactInfoModel[forms.billingForm.contactInfoFields.cpfCnpj.htmlName]",
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: _vm.cpfCnpjMask,
                      expression: "cpfCnpjMask",
                    },
                  ],
                  staticClass: "form-control cpfCnpj checkoutInputs",
                  class: {
                    error:
                      _vm.formErrorsContact
                        .dwfrm_billing_contactInfoFields_cpfCnpj,
                  },
                  attrs: {
                    type: "text",
                    id: "cpfCnpj",
                    required:
                      _vm.forms.billingForm.contactInfoFields.cpfCnpj
                        .mandatory === true
                        ? true
                        : false,
                    inputmode: "numeric",
                    placeholder: "Digite seu CPF ou CNPJ",
                    maxlength:
                      _vm.forms.billingForm.contactInfoFields.cpfCnpj.maxLength,
                    minLength:
                      _vm.forms.billingForm.contactInfoFields.cpfCnpj.minLength,
                  },
                  domProps: {
                    value:
                      _vm.contactInfoModel[
                        _vm.forms.billingForm.contactInfoFields.cpfCnpj.htmlName
                      ],
                  },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.contactInfoModel,
                          _vm.forms.billingForm.contactInfoFields.cpfCnpj
                            .htmlName,
                          $event.target.value
                        )
                      },
                      _vm.validateCpfCnpj,
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.formErrorsContact
                            .dwfrm_billing_contactInfoFields_cpfCnpj,
                        expression:
                          "formErrorsContact.dwfrm_billing_contactInfoFields_cpfCnpj",
                      },
                    ],
                    staticClass: "error-message",
                    attrs: { id: "cpfInvalidMessage" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.formErrorsContact
                            .dwfrm_billing_contactInfoFields_cpfCnpj
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  staticClass: "loader",
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isDocChecked,
                        expression: "isDocChecked",
                      },
                    ],
                    staticClass: "icon-doc",
                  },
                  [_c("i", { staticClass: "icon-check" })]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.changeFlag,
                expression: "changeFlag",
              },
            ],
            class: _vm.isMobile ? "col-3" : "col-2",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn-change",
                attrs: { type: "button" },
                on: { click: _vm.changeDoc },
              },
              [
                _c("i", { staticClass: "icon-change" }),
                _vm._v(" "),
                _c("span", [_vm._v("Alterar")]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.innerStep === 2 || _vm.logged
        ? _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "column" } },
            [
              _c("shipping-form", {
                attrs: {
                  errors: _vm.formErrors,
                  user: _vm.user,
                  type: _vm.documentType,
                  isMobile: _vm.isMobile,
                },
                on: { updateErrors: _vm.updateFormErr },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }