var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addList_wrap" }, [
    _vm.islogged === "true"
      ? _c(
          "button",
          {
            staticClass: "add-product-to-product-list icon-pdp-font",
            attrs: { "data-pid": _vm.productId },
          },
          [_c("span", { staticClass: "icon-favorite_border" })]
        )
      : _c(
          "a",
          {
            staticClass: "add-to-productlist-login-btn icon-pdp-font",
            attrs: { href: _vm.urllogin, "data-pid": _vm.productId },
          },
          [_c("span", { staticClass: "icon-favorite_border" })]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }