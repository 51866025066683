<template>
    <div class="modal fade container-modal" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'"
        aria-hidden="true" :data-backdrop="isStatic ? 'static' : 'true'">
        <div class="modal-dialog modal-dialog-centered" :class="sizeClass">
            <div class="modal-content">
                <div class="modal-header">
                    <p class="modal-title" :id="modalId + 'Label'" :class="{center: centerTitle}"> <slot name="title"></slot></p>
                    <i type="button" class="close btn-close icon-close" data-bs-dismiss="modal" aria-label="Close" v-if="showCloseButton" @click="handleClose"></i>
                </div>
                <div class="modal-body" v-if="$slots.body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        modalId: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            validator: (value) => ['sm', 'md', 'lg', 'xl'].includes(value),
            default: 'md',
        },
        showCloseButton: {
            type: Boolean,
            default: true,
        },
        isStatic: {
            type: Boolean,
            default: false,
        },
        startOpen: {
            type: Boolean,
            default: false,
        },
        centerTitle: {
            type: Boolean,
            default: false,
        },
        timerModal: {
            type: Boolean,
            default: false,
        },
        redirectTime: {
            type: Number,
        },
        redirectUrl: {
            type: String,
        }
    },
    computed: {
        sizeClass() {
            switch (this.size) {
                case 'sm':
                    return 'modal-sm';
                case 'md':
                    return '';
                case 'lg':
                    return 'modal-lg';
                case 'xl':
                    return 'modal-xl';
                default:
                    return '';
            }
        },
    },
    mounted() {
        setTimeout(() => {
            var self = this;
            if (this.startOpen) {
                $(`#${this.modalId}`).modal('show');
            }
            $(`#${this.modalId}`).on('hidden.bs.modal', function (event) {
                self.$emit("close")
            });
        }, 300);
    },
    methods: {
        handleClose() {
            $(`#${this.modalId}`).modal('hide');
            this.$emit('hande:close');
        }
    }
}
</script>
