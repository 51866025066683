import routes from '../../api/routes';
import loginApi from '../../api/login';

const state = () => ({
    logged: false,
    loginErr: null,
    resetErr: null,
    emailSend: false,
    showResetForm: false,
    email: '',
    whatslink:'',
    whatsnumber:''
});

const getters = {
    logged:(state) => {
        return state.logged;
    },
    loginErr:(state) => {
        return state.loginErr;
    },
    resetErr:(state) => {
        return state.resetErr;
    },
    emailSend:(state) => {
        return state.emailSend;
    },
    showResetForm:(state) => {
        return state.showResetForm;
    },
    email:(state) => {
        return state.email;
    },
    whatslink:(state) => {
        return state.whatslink;
    },
    whatsnumber:(state) => {
        return state.whatsnumber;
    },
};

const actions = {
    login ({ commit, dispatch, rootState }, data) {
        $('.cta-button').addClass('is__loading')
        let req = loginApi.submitLogintReq(
            routes.account.logincheckout,
            data,
            rootState
        );
        $.ajax({
            url: req.url,
            type: 'post',
            dataType: 'json',
            data: req.options.body,
            success: function (data) {
                if(data.success) {
                    $('.cta-button').removeClass('is__loading')
                    location.href = data.redirectUrl
                } else {
                    commit('setLoginErr', data.error[0])
                    $('.cta-button').removeClass('is__loading')
                }
            },
            error: function (data) {

            }
        })
    },

    passwordResetDialogForm ({ commit, dispatch, rootState }, data) {
        $('.js-reset-password').addClass('is__loading')
        let req = loginApi.submitLogintReq(
            routes.account.passwordResetDialogForm,
            data,
            rootState
        );
        $.ajax({
            url: req.url,
            type: 'post',
            dataType: 'json',
            data: req.options.body,
            success: function (data) {
                if(data.success) {
                    commit('setEmailSend', true)
                }else{
                    commit('setResetErr', data.error[0])
                    commit('setEmailSend', false)
                }
                $('.js-reset-password').removeClass('is__loading')
            },
            error: function (data) {

            }
        })
    },

};

const mutations = {
    setLogged(state, data){
        state.logged = data;
    },
    setLoginErr(state, data){
        state.loginErr = data;
    },
    setResetErr(state, data){
        state.resetErr = data;
    },
    setEmailSend(state, data){
        state.emailSend = data;
    },
    setShowResetForm(state, data){
        state.showResetForm = data;
    },
    setEmail(state, data){
        state.email = data;
    },
    setWhatslink(state, data){
        state.whatslink = data;
    },
    setWhatsnumber(state, data){
        state.whatsnumber = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
