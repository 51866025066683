<template>
  <span class="tooltip-box" @click.stop="toggleOpen">
    <i class="icon-info"></i>
    <span class="tooltip-box-text" :class="showTooltip ? 'tooltip-show' : ''">
      {{ msg }}
    </span>
  </span>
</template>

<script>
export default {
  props: ['msg'],
  data() {
    return {
      showTooltip: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      document.querySelectorAll('.tooltip-box-text.tooltip-show').forEach(tooltip => {
        tooltip.classList.remove('tooltip-show');
        this.showTooltip = false;
      });
    },
    toggleOpen() {
      if (window.innerWidth < 1024) {
        console.log("TABLET/MOBILE");
        this.showTooltip = !this.showTooltip;
      }
      console.log("Button clicked");
    },
  }
}
</script>

<style scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip-box i:before {
  color: #EEAF1B;
}

.tooltip-box .tooltip-box-text {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  width: 200px;
  padding: 6px;
  border-radius: 5px 5px 5px 0px;
  border: 1px solid #D0D0D0;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  font-size: 10px;
  font-style: normal;
  text-align: center;
  bottom: 20px;
  left: 50%;

  @media (max-width: 768px) {
    top: 15px;
    left: -194px;
    height: 40px;
    border-radius: 5px 0px 5px 5px;
  }
}

.tooltip-show {
  visibility: visible !important;
}

@media (min-width: 1025px) {
  .tooltip-box:hover .tooltip-box-text {
    visibility: visible;
  }
}
</style>