<template>
    <div class="p-3 reviewBox">
        <div class="reviewBox__content">

            <h2>DETALHES DO PEDIDO</h2>
            <!-- product summary -->
            <list-item></list-item>

        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ListItem from './checkout/ListItem';

    export default {
        components: {
            ListItem,
        },

        data() {
            return {

            };
        },

        created() {
        },

    }

</script>