var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "handle-step__content",
      on: {
        click: function ($event) {
          return _vm.$emit("handle:click")
        },
      },
    },
    [
      _c("i", {
        staticClass: "icon-chevron-right",
        staticStyle: { transform: "rotate(180deg)" },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Voltar")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }