import routes from '../../api/routes';
import checkoutApi from '../../api/checkout';

const state = () => ({
    order: {},
    billing: {},
    totals: {},
    quotationMethods: {},
    quotationLinks: {},
    isShareQuotation: false,
    customer: {},
    addressModal: {},
    forms: {
        shippingForm: {},
        billingForm: {}
    },
    expirationYears: {},
    currentStage: {},
    reportingURLs: {},
    changeStep: null,
    csrf: {
        token: '',
        tokenName: '',
    },
    addressModel: {},
    paymentFinished: false,
    errorMessage: null,
    errorMessageHtml: false,
    maxAttemptError: false,
    maxAttemptTimeout: 1,
    closeurl: null,
    selectedAddressIdModel: null,
    quotationObj: null,
    quotationSelected: null,
});

const getters = {
    order:(state) => {
        return state.order;
    },
    billing: (state) => {
        return state.billing
    },
    totals:(state) => {
        return state.totals;
    },
    quotationMethods:(state) => {
        return state.quotationMethods;
    },
    quotationLinks:(state) => {
        return state.quotationLinks;
    },
    isShareQuotation:(state) => {
        return state.isShareQuotation;
    },
    customer:(state) => {
        return state.customer;
    },
    addressModal:(state) => {
        return state.addressModal;
    },
    forms:(state) => {
        return state.forms;
    },
    expirationYears:(state) => {
        return state.expirationYears;
    },
    currentStage:(state) => {
        return state.currentStage;
    },
    reportingURLs:(state) => {
        return state.reportingURLs;
    },
    csrf: (state) => {
        return state.csrf;
    },
    changeStep: (state) => {
        return state.changeStep;
    },
    addressModel: (state) => {
        return state.addressModel;
    },
    paymentFinished: (state) => {
        return state.paymentFinished;
    },
    errorMessage: (state) => {
        return state.errorMessage;
    },
    errorMessageHtml: (state) => {
        return state.errorMessageHtml;
    },
    maxAttemptError: (state) => {
        return state.maxAttemptError;
    },
    maxAttemptTimeout: (state) => {
        return state.maxAttemptTimeout;
    },
    closeurl: (state) => {
        return state.closeurl;
    },
    selectedAddressIdModel: (state) => {
        return state.selectedAddressIdModel;
    },
    quotationObj: (state) => {
        return state.quotationObj;
    },
    quotationSelected: (state) => {
        return state.quotationSelected;
    }
};

const actions = {
    begin({ commit, dispatch, rootState }, data) {
        commit('checkout/setData', data, { root: true });
        commit('totals/set', data.totals, { root: true });
    },

    async checkUserState({ commit, dispatch, rootState }, email) {
        let url = `${routes.checkout.validateUser}?customerEmail=${email}`

        try {
            const response = await fetch(url);
            const data = await response.json();

            if(!data.error) {
                return (data.customerHasProfile ? 2 : 3)
            } else {
                return 1
            }

        } catch (error) {
            console.log('Fetch error checkUserState: ', error);
        }
    },

    async confirmShipping({ commit, dispatch, rootState }, data) {
        let req = checkoutApi.submitShippingReq(
            routes.checkout.confirmShipping,
            data,
            rootState
        );

        try {
            const response = await fetch(req.url, req.options);
            const data = await response.json();

            if(!data.error){
                commit('setUpdateShipping', data);
            }

            if(data.maxAttemptError) {
                commit('setMaxAttemptError', data.maxAttemptError);
                commit('setMaxAttemptTimeout', data.maxAttemptTimeout);
            }

            return data;
        } catch (error) {
            console.log('Fetch error confirmShipping: ', error);
        }
    },

    async quotationSelect({ commit, dispatch, rootState }, sellerObj) {

        let url = `${routes.quotation.select}?${Object.keys(sellerObj).map((key, index) => `${key}=${sellerObj[key]}`).join('&')}`;

        try {
            const response = await fetch(url,{
                method: 'POST',
                body: JSON.stringify(sellerObj)
            });
            const data = await response.json();

            if(data.success){
                commit('setOrder', data.orderModel);
                dispatch('totals/set', data.orderModel.totals, { root: true });
            }

            return data;
        } catch (error) {
            console.log('Fetch error quotationSelect: ', error);
        }
    },

    async addCoupon({ commit, dispatch, state }, couponCode) {
        if (!couponCode) return;

        let url = `${routes.cart.addCoupon}?couponCode=${couponCode}&csrf_token=${state.csrf.token}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data && data.totals) {
                //TODO: validar se precisa atualizar o checkout commit('setData', data)
                dispatch('totals/set', data.totals, { root: true })
            }
            return data;

        } catch (error) {
            console.log('Fetch error addCoupon: ', error);
        }
    },

    async removeCoupon({ commit, dispatch, state}, couponUuid) {
        if (!couponUuid) return;

        let url = `${routes.cart.removeCoupon}?uuid=${couponUuid}&csrf_token=${state.csrf.token}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data && data.items && data.totals) {
                //TODO: validar se precisa atualizar o checkout commit('setData', data)
                dispatch('totals/set', data.totals, { root: true });
            }
            return data;

        } catch (error) {
            console.log('Fetch error removeCoupon: ', error);
        }
    },

    async submitPayment({ commit, dispatch, rootState }, data) {

        let req = checkoutApi.submitPaymentReq(
            routes.checkoutServices.submitPayment,
            data,
            rootState
        );

        try {
            const response = await fetch(req.url, req.options);
            const data = await response.json();

            if(!data.error){
                dispatch('totals/set', data.order.totals, { root: true });
                commit('setPaymentFinished', true);
                // commit('setChangeStep', 4);
                commit('setAddressSelected', data);
            } else {
                commit('setPaymentFinished', false);
                if(data.errorMessage) {
                    console.log("🚀 ~ submitPayment ~ data.modalError:", data.modalError)

                    if(data.maxAttemptError) {
                        commit('setMaxAttemptError', data.maxAttemptError);
                        commit('setMaxAttemptTimeout', data.maxAttemptTimeout);
                    }

                    $(".continue-btn").removeClass("is__loading");
                    if (data.modalError) {
                        commit("setErrorMessageHtml", true);
                        commit('setErrorMessage', null);
                    } else {
                        commit("setErrorMessageHtml", false);
                        commit('setErrorMessage', data.errorMessage);
                    }
                    $('#modalMessage').modal('show');

                }
            }

            return data;
        } catch (error) {
            console.log('Fetch error subimitPayment: ', error);

            // error in submit payment
            commit("setErrorMessageHtml", true);
            $('#modalMessage').modal('show');
            $(".continue-btn").removeClass("is__loading");
        }
    },

    async removeBlockedCustomer({ commit, dispatch, state }, email) {
        // console.log("🚀 ~ removeBlockedCustomer ~ data:", data);

        let url = `${routes.checkoutServices.removeBlockedCustomer}?customerEmail=${email}&csrf_token=${state.csrf.token}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            return data
            
        } catch (error) {
            console.log('Fetch error removeBlockedCustomer: ', error);
        }
    },

    async placeOrder({ commit, dispatch, rootState }) {
       commit("setErrorMessageHtml", false);

        try {
            const response = await fetch(routes.checkoutServices.placeOrder);
            const data = await response.json();

            if (!data.error) {
                var continueUrl = data.continueUrl;
                var urlParams = {
                    orderID: data.orderID,
                    orderToken: data.orderToken
                };
                continueUrl += (continueUrl.indexOf('?') !== -1 ? '&' : '?') +
                    Object.keys(urlParams).map(function (key) {
                        return key + '=' + encodeURIComponent(urlParams[key]);
                    }).join('&');
                window.location.replace(continueUrl);
            } else {
                if (data.errorMessage) {
                    $(".continue-btn").removeClass("is__loading");

                    if (data.modalError) {
                        commit("setErrorMessageHtml", true);
                    } else {
                        commit('setErrorMessage', data.errorMessage);
                    }
                    $('#modalMessage').modal('show');
                }
            }

            return data;
        } catch (error) {
            console.log('Fetch error placeOrder: ', error);

            // error in place order
            commit("setErrorMessageHtml", true);
            $('#modalMessage').modal('show');
            $(".continue-btn").removeClass("is__loading");
        }

    }
};

const mutations = {
    setData(state, data) {
        state.order = data.order;
        state.billing = data.order.billing;
        state.totals = data.totals;
        state.quotationMethods = data.quotationMethods;
        state.quotationLinks = data.quotationLinks;
        state.isShareQuotation = data.isShareQuotation;
        state.customer = data.customer;
        state.addressModal = data.addressModal;
        state.forms.shippingForm = data.forms.shippingForm;
        state.forms.billingForm = data.forms.billingForm;
        state.expirationYears = data.expirationYears;
        state.currentStage = data.currentStage;
        state.reportingURLs = data.reportingURLs;
        state.csrf.tokenName = data.csrf.tokenName;
        state.csrf.token = data.csrf.token;
    },
    setAddressSelected(state, data) {
        state.order = data.order;
        state.customer = data.customer;
    },
    setUpdateShipping(state, data) {
        state.order = data.order;
        state.billing = data.order.billing;
        state.customer = data.customer;
    },
    setToken(state, data) {
        state.csrf = data.csrf;
    },
    setChangeStep(state, data) {
        state.changeStep = data;
    },
    setAddressModel(state, data) {
        state.addressModel = data;
    },
    setOrder(state, data) {
        state.order = data;
    },
    setPaymentFinished(state, data) {
        state.paymentFinished = data;
    },
    setErrorMessage(state, data) {
        state.errorMessage = data;
    },
    setErrorMessageHtml(state, data) {
        state.errorMessageHtml = data;
    },
    setMaxAttemptError(state, data) {
        state.maxAttemptError = data;
    },
    setMaxAttemptTimeout(state, data) {
        state.maxAttemptTimeout = data;
    },
    setCloseurl(state, data) {
        state.closeurl = data;
    },
    setSelectedAddressIdModel(state,data) {
        state.selectedAddressIdModel = data;
    },
    setQuotationObj(state,data) {
        state.quotationObj = data;
    },
    setQuotationSelected(state,data) {
        state.quotationSelected = data;
    },
    setIsShareQuotation(state,data) {
        state.isShareQuotation = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

