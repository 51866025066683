var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade container-modal",
      attrs: {
        id: _vm.modalId,
        tabindex: "-1",
        "aria-labelledby": _vm.modalId + "Label",
        "aria-hidden": "true",
        "data-backdrop": _vm.isStatic ? "static" : "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          class: _vm.sizeClass,
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "p",
                {
                  staticClass: "modal-title",
                  class: { center: _vm.centerTitle },
                  attrs: { id: _vm.modalId + "Label" },
                },
                [_vm._t("title")],
                2
              ),
              _vm._v(" "),
              _vm.showCloseButton
                ? _c("i", {
                    staticClass: "close btn-close icon-close",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "modal",
                      "aria-label": "Close",
                    },
                    on: { click: _vm.handleClose },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.$slots.body
              ? _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2)
              : _vm._e(),
            _vm._v(" "),
            _vm.$slots.footer
              ? _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }