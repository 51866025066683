var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "formLogin-socials" }, [
    _c(
      "form",
      {
        staticClass: "login-oauth",
        attrs: { action: "", method: "post", name: "login-oauth-form" },
      },
      [
        _c(
          "a",
          {
            staticClass: "oauth-google btn",
            attrs: {
              href: _vm.GoogleUrl,
              role: "button",
              "aria-pressed": "true",
            },
          },
          [
            _c("img", {
              attrs: { src: _vm.iconGoogle, alt: "connect with Google" },
            }),
            _vm._v("\n            Entrar com Google\n        "),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }