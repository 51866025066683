var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c(
      "div",
      {
        staticClass: "share-quoatation-container",
        on: { click: _vm.toggleExpanded },
      },
      [
        _c("span", [
          _vm.expanded
            ? _c("i", { staticClass: "icon-close" })
            : _c("i", { staticClass: "icon-share" }),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "d-none d-lg-block mb-0" }, [
          _vm._v("Compartilhar cotação"),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.expanded,
            expression: "expanded",
          },
        ],
        staticClass: "share-quotation-links-modal",
      },
      [
        _c("ul", { staticClass: "nav", attrs: { role: "menu" } }, [
          _c("p", { staticClass: "d-lg-none nav-item mb-0 title" }, [
            _vm._v("Compartilhar cotação"),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                attrs: { href: _vm.quotationLinks.whatsapp, target: "_blank" },
              },
              [_c("i", { staticClass: "icon-whatsapp" }), _vm._v(" WhatsApp")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item", on: { click: _vm.handleCopyLink } },
            [_c("i", { staticClass: "icon-link" }), _vm._v(" Copiar Link")]
          ),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              { attrs: { href: _vm.quotationLinks.email, target: "_blank" } },
              [_c("i", { staticClass: "icon-email" }), _vm._v(" E-mail")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }