<template>
  <div class="checkout-summary" v-if="$root.$refs.Checkout.activeStep!==1">
    <!-- Botão para mostrar/ocultar detalhes -->
    <div class="toggle-details__container">
      <button class="toggle-details-btn" @click="toggleDetails">
        <span>Detalhes do Pedido</span>
      </button>
      <i class="icon-chevron-right" :class="{'active': showDetails}"></i>
    </div>

    <!-- Detalhes do Pedido -->
    <div v-if="showDetails" class="order-details">
        <div class="priceResume">
            <p>Subtotal<span>{{ totals.totalWithoutDiscount.formatted }}</span></p>
            <p v-if="totals.grandTotalDiscount.formatted">Desconto<span> - {{ totals.grandTotalDiscount.formatted }}</span></p>
            <p>Frete<span :class="{'text-green': totals.totalShippingCost.formatted.trim() === 'Grátis'}" class="shipping-total-cost">
                        {{ totals.totalShippingCost.formatted }}
            </span>
          </p>
        </div>
    </div>

    <div class="priceResume total" >
        <p class="priceTotals">Total<span>{{ totals.grandTotal.formatted }}</span></p>
    </div>

    <!-- cta back home -->
    <a class="summary-back-home-btn" :href="homeUrl" v-show="showBack">Continuar Comprando</a>

    <!-- CTA para continuar -->
    <button v-show="showCta" class="continue-btn" @click="handleClick" :disabled="disabled">
      {{ fineshed ? 'Finalizar Compra' : 'Continuar' }}
      <i class="icon-chevron-right" style="margin-left: 10px; font-size: 25px;"></i>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routes from '../../api/routes';

export default {
  props: {
    enabled: Boolean,
  },
  data() {
    return {
      showDetails: false,
      disabled: false,
      fineshed: false,
      homeUrl: routes.home.show,
      showCta: true,
      showBack: false
    };
  },
  created() {
      this.$root.$refs.CheckoutSummary = this;
  },
  computed: {
    ...mapGetters('totals', {
      totals: 'totals',
    })
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    async handlePlaceOrder(){
      var result = await this.$store.dispatch('checkout/placeOrder');
    },
    handleClick(){
      $(".continue-btn").addClass("is__loading");
      if(this.$root.$refs.Checkout.activeStep === 2){
          this.$root.$refs.ShippingStep.handleClick()
      } else if(this.$root.$refs.Checkout.activeStep === 3) {
          this.$root.$refs.PaymentStep.subimitPayment()
      }else if(this.fineshed) {
          this.handlePlaceOrder()
      }
    },
  }
};
</script>

<style scoped>
.text-green {
    color: #00744A;
}
</style>