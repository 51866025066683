var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkout-resume-box" }, [
    _c("div", { staticClass: "p-3 resumeBox" }, [
      _c(
        "div",
        { staticClass: "summaryBox", class: { fineshed: _vm.fineshed } },
        [
          _c(
            "div",
            { staticClass: "order-total-summary" },
            [
              _c("h2", [_vm._v("RESUMO DO PEDIDO")]),
              _vm._v(" "),
              _c("order-total-summary"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "next-step-button" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showBack,
                    expression: "showBack",
                  },
                ],
                staticClass: "summary-back-home-btn",
                attrs: { href: _vm.homeUrl },
              },
              [_vm._v("Continuar Comprando")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCta,
                    expression: "showCta",
                  },
                ],
                staticClass: "continue-btn",
                attrs: { disabled: _vm.disabled },
                on: { click: _vm.handleClick },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.fineshed ? "Finalizar Compra" : "Continuar") +
                    "\n                    "
                ),
                _c("i", { staticClass: "icon-chevron-right" }),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }