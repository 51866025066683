<template>
  <div class="box_wrap box gallery-box">
    <image-gallery :images="imagesParsed"></image-gallery>
    <add-product-list :productId="productid" :islogged="islogged" :urllogin="urllogin"></add-product-list>
  </div>
</template>

<script>
import ImageGallery from './ImageGallery';
import AddProductList from './AddProductList';
export default {
  props: ['images', 'productid', 'islogged', 'urllogin'],
  components: {
    ImageGallery,
    AddProductList
  },
  data() {
    return {
      imagesParsed: null,
    };
  },
  created() {
    this.imagesParsed = JSON.parse(this.images);
  }
}
</script>

<style>
  @media (min-width: 992px) {
        .gallery-box {
        height: 500px;
    }

    .gallery-box div {
        height: 100%;
        overflow: hidden;
    }
    .gallery-box div img {
        width: 100%;
        height: auto;
        max-width: 440px;
        margin: auto;
        max-height: 440px;
    }
    .gallery-box div .img-pdp-thumbnailCarousel {
      max-width: 100%; 
      height: auto; 
      display: block; 
    }
  }
  @media (max-width: 992px) {
    .gallery-box {
      position: relative;
      background-color: unset!important;
      border: none !important;
      padding: 0px !important;
    }
    .addList_wrap{
      top: 15px;
      right: 15px;
    }
  }
</style>
