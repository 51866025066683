<template>
    <div id="pdp-main" class="container">
        <breadcrumb :breadcrumbs="pData.breadcrumbs"></breadcrumb>
        <div class="row">
            <div class="col-12 col-sm-6">
                <long-description :long-description="pData.product.longDescription"></long-description>
            </div>
            <div class="col-12 col-sm-6">
                <product-summary :product="pData.product"></product-summary>
            </div>
            <!-- <product-carousel title="Quem viu este produto também comprou" :recommendations="pData.product"></product-carousel> -->
            <!-- <product-card :product="pData.product"></product-card> -->

        </div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb';
import LongDescription from '../components/productPage/LongDescription';
import ProductSummary from '../components/productPage/ProductSummary';
import ProductCard from '../components/productPage/ProductCard';
import ProductCarousel from '../components/productPage/ProductCarousel';
    export default {
        props: ['pdpdata'],
        components: {
            Breadcrumb,
            LongDescription,
            ProductSummary,
            ProductCarousel,
            ProductCard
        },
        created() {
            this.$root.$refs.ProductPage = this;
            this.pData = JSON.parse(this.pdpdata);
        },
        data() {
            return {
                pData: null,
            }
        }
    };
</script>