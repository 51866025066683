<template>
    <div class="quantityAndShop">
        <input type="hidden" class="add-to-cart-url" :value="urlAddCart" />
        <button
            class="quickBuyBtn add-to-cart-carousel js-pdp-add"
            :data-pid="id"
            :data-pidsobj="idObj"
            :id="`quickBuyBtn-${id}`"
            :disabled="!productAvailable">
            Adicionar ao Carrinho
        </button>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            default: null,
            type: String
        },
        pidsobj: {
            default: null,
            type: Array
        },
        uuid: {
            default: null,
            type: String
        },
        uuidLineItem: {
            default: null,
            type: String
        },
        use: {
            default: null,
            type: String
        },
        productAvailable: {
            default: null,
            type: Boolean
        },
        uuidLineItem: {
            default: null,
            type: String
        },
        quantity: {
            default: 0,
            type: Number
        },
        urlAddCart: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            idObj: null,
            quantityFormat: parseInt(this.quantity)
        };
    },
    created() {
        this.idObj = this.pidsobj ? JSON.stringify(this.pidsobj) : null;
    }
};
</script>
