var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box_wrap" }, [
    _c("p", { staticClass: "title" }, [_vm._v("Informações adicionais")]),
    _vm._v(" "),
    _c("div", {
      staticClass: "long-description box",
      domProps: { innerHTML: _vm._s(_vm.longDescription) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }