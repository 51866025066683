<template>
    <div class="p-3 paymentBox">
        <div class="paymentBox__content">
            <!-- dados de pagamento -->
            <template v-if="innerStep===1">
                <h2>FORMAS DE PAGAMENTO</h2>
                <coupon></coupon>
                <payment-options
                    :paymentMethodModel="paymentMethodModel"
                    :creditCardModel="creditCardModel"
                    :errorsCreditCard="formErrorsCC">
                </payment-options>
            </template>

        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AddressSelector from './billing/AddressSelector';
    import BillingAddress from './billing/BillingAddress';
    import PaymentOptions from './payment/PaymentOptions';
    import Coupon from './Coupon';
    import HandleStep from './HandleStep';

    export default {
        components: {
            AddressSelector,
            BillingAddress,
            PaymentOptions,
            HandleStep,
            Coupon
        },
        props: ['activeStep'],
        data() {
            return {
                billingListModel: {
                    'dwfrm_billing_addressFields_firstName': '',
                    'dwfrm_billing_addressFields_lastName': '',
                    'dwfrm_billing_addressFields_postalCode': '',
                    'dwfrm_billing_addressFields_address1': '',
                    'dwfrm_billing_addressFields_at__address__district': '',
                    'dwfrm_billing_addressFields_at__address__number': '',
                    'dwfrm_billing_addressFields_city': '',
                    'dwfrm_billing_addressFields_states_stateCode': '',
                },
                billingAddressModel: {
                    'dwfrm_billing_addressFields_firstName': '',
                    'dwfrm_billing_addressFields_lastName': '',
                    'dwfrm_billing_addressFields_postalCode': '',
                    'dwfrm_billing_addressFields_address1': '',
                    'dwfrm_billing_addressFields_at__address__district': '',
                    'dwfrm_billing_addressFields_at__address__number': '',
                    'dwfrm_billing_addressFields_city': '',
                    'dwfrm_billing_addressFields_states_stateCode': '',
                },
                paymentMethodModel: {
                    'dwfrm_billing_paymentMethod': '',
                },
                creditCardModel: {
                    'dwfrm_billing_creditCardFields_cardOwner': '',
                    'dwfrm_billing_creditCardFields_cardNumber': '',
                    'dwfrm_billing_creditCardFields_securityCode': '',
                    'dwfrm_billing_creditCardFields_expirationYear': '',
                    'dwfrm_billing_creditCardFields_expirationMonth': '',
                },
                formErrors: {},
                formErrorsCC: {},
                showNewAddressForm: false,
                disabledNext: false,
                innerStep: 1,
            };
        },
        created() {
            this.$root.$refs.PaymentStep = this;
        },
        computed: {
            ...mapGetters('checkout', {
                forms: "forms",
                addressModel: 'addressModel',
                csrf: 'csrf',
                order: 'order',
                selectedAddressIdModel: 'selectedAddressIdModel',
            })
        },



        methods: {
            populateBillingModel(){
                // console.log("🚀 ~ populateBillingModel ~ populateBillingModel: ", this.selectedAddressIdModel);

                //if(this.selectedAddressIdModel){    
                    this.order.shipping.forEach(function(address) {
                        //if (address.UUID === this.selectedAddressIdModel) {
                            // console.log("🚀 ~  this.order.shipping: ");
                            // console.log("🚀 ~ this.order.shipping.forEach ~ address:", address)
                            this.billingListModel['dwfrm_billing_addressFields_firstName'] = address.shippingAddress.firstName
                            this.billingListModel['dwfrm_billing_addressFields_lastName'] = address.shippingAddress.lastName
                            this.billingListModel['dwfrm_billing_addressFields_postalCode'] = address.shippingAddress.postalCode
                            this.billingListModel['dwfrm_billing_addressFields_address1'] = address.shippingAddress.address1
                            this.billingListModel['dwfrm_billing_addressFields_at__address__district'] = address.shippingAddress.at_address_district
                            this.billingListModel['dwfrm_billing_addressFields_at__address__number'] = address.shippingAddress.at_address_number
                            this.billingListModel['dwfrm_billing_addressFields_city'] = address.shippingAddress.city
                            this.billingListModel['dwfrm_billing_addressFields_states_stateCode'] = address.shippingAddress.stateCode
                        //}
                    }.bind(this));
                //}
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
            validateStep(step) {
                if (step <= this.activeStep) return true;
                else return false;
            },
            handleBackClick() {
                // console.log("🚀 ~ handleBackClick ~ handleBackClick:");
                this.$root.$refs.Checkout.backStep();
            },
            validateForm() {
                this.formErrors = {};
                this.formErrorsCC = {};

                var errorsAddress = true;
                var errorPayment = true;

                // is new address billing
                if (this.showNewAddressForm) {
                    Object.keys(this.billingAddressModel).forEach(key => {
                        if (this.billingAddressModel[key] === '') {
                            this.$set(this.formErrors, key, 'Campo obrigatório.');
                        }
                    });
                    errorsAddress = Object.values(this.formErrors).every(error => !error);
                }

                // is creditcard paymentMethod
                if (this.paymentMethodModel.dwfrm_billing_paymentMethod === "IUGU_CREDIT_CARD") {
                    Object.keys(this.creditCardModel).forEach(key => {
                        if (this.creditCardModel[key] === '') {
                            this.$set(this.formErrorsCC, key, 'Campo obrigatório.');
                        }
                        else if (key==='dwfrm_billing_creditCardFields_securityCode' && this.creditCardModel.dwfrm_billing_creditCardFields_securityCode.length<3) this.$set(this.formErrorsCC, 'dwfrm_billing_creditCardFields_securityCode', 'Campo Inválido.')
                    });
                    errorPayment = Object.values(this.formErrorsCC).every(error => !error);
                }

                const errorModel = errorsAddress === true && errorPayment === true ? true : false;
                return errorModel;
            },
            goToStep(step) {
                this.innerStep = step;
            },
            async submitPaymentForm(model) {
                var result = await this.$store.dispatch('checkout/submitPayment', { ...model, addressId: model.dwfrm_billing_addressFields_postalCode + ' ' + model.dwfrm_billing_addressFields_address1 + ' ' + model.dwfrm_billing_addressFields_at__address__number, csrf_token: this.csrf.token });

                if (!result.error) {
                    //datalayer trigger add_payment_info
                    $('body').trigger('dataLayerPush:event', result.add_payment_info);
                    this.$root.$refs.CheckoutReview.handlePlaceOrder()

                } else {
                    if (
                        Object.prototype.hasOwnProperty.call(result, 'serverErrors') 
                        && result.serverErrors.length > 0
                    ) {
                        var errors = JSON.parse(result.serverErrors[0]).errors;
                        Object.keys(errors).forEach(key => {
                            if (key === 'number') {
                                this.$set(this.formErrorsCC, 'dwfrm_billing_creditCardFields_cardNumber', 'Número do cartão inválido');
                            }  else if (key === 'securityCode') {
                                this.$set(this.formErrorsCC, 'dwfrm_billing_creditCardFields_securityCode', 'Código de segurança inválido');
                            } else if (key === 'expirationMonth') {
                                this.$set(this.formErrorsCC, 'dwfrm_billing_creditCardFields_expirationMonth', 'Mês de expiração inválido');
                            } else if (key === 'expirationYear') {
                                this.$set(this.formErrorsCC, 'dwfrm_billing_creditCardFields_expirationYear', 'Ano de expiração inválido');
                            } else {
                                this.$set(this.formErrorsCC, 'dwfrm_billing_creditCardFields_cardNumber', 'Erro ao processar pagamento');
                            }
                        });
                    }
                    $(".continue-btn").removeClass("is__loading");
                }
            },
            validatePayment() {
                if (this.validateForm()) this.innerStep = 2
            },
            validatePaymentModel() {
                var PaymentModel = { ...this.$root.$refs.ShippingStep.contactInfoModel, ...this.paymentMethodModel }
                // console.log("🚀 ~ validatePaymentModel ~ PaymentModel:", PaymentModel);
                // is new billing address?
                if (this.showNewAddressForm) {
                    PaymentModel = {
                        ...PaymentModel,
                        ...this.billingAddressModel
                    }
                } else {
                    PaymentModel = {
                        ...PaymentModel,
                        ...this.billingListModel
                    }
                }

                // is creditcard paymentMethod
                if (this.paymentMethodModel.dwfrm_billing_paymentMethod === "IUGU_CREDIT_CARD") {
                    PaymentModel = {
                        ...PaymentModel,
                        ...this.creditCardModel
                    }
                }
                return PaymentModel
            },
            subimitPayment() {
                // console.log("🚀 ~ subimitPayment ~ subimitPayment:");
                var PaymentModel = this.validatePaymentModel();
                // console.log("🚀 ~ subimitPayment ~ PaymentModel:", PaymentModel)

                if (this.validateForm()) {
                    this.submitPaymentForm(PaymentModel);
                } else {
                    $(".continue-btn").removeClass("is__loading");
                }
            },
            showAddNewAddress(show) {
                this.showNewAddressForm = show;
                // console.log("🚀 ~ showAddNewAddress ~ show:", show);
            }
        },
        watch: {
            innerStep(newVal) {
                if(newVal === 2) {
                    this.$root.$refs.CheckoutSummary.showCta = true;
                    this.$root.$refs.CheckoutSummary.disabled = false;
                } else {
                    this.$root.$refs.CheckoutSummary.showCta = false;
                    this.$root.$refs.CheckoutSummary.disabled = true;
                }
            }
        },
    }

</script>