var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loginErr,
            expression: "loginErr",
          },
        ],
        staticClass: "alert alert-danger",
        attrs: { role: "alert" },
      },
      [_vm._v("\n        " + _vm._s(_vm.loginErr) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "login",
        attrs: { method: "POST", name: "login-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-group required" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "login-form-email" },
            },
            [_vm._v("\n                E-mail\n            ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "form-control required",
            class: { error: _vm.errors.email },
            attrs: {
              type: "email",
              id: "login-form-email",
              placeholder: "Digite seu e-mail",
              required: "",
              "aria-required": "true",
              "aria-describedby": "form-email-error",
              name: "loginEmail",
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.email,
                  expression: "errors.email",
                },
              ],
              staticClass: "invalid-feedback",
              attrs: { id: "form-email-error" },
            },
            [_vm._v(_vm._s(_vm.errors.email))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group required passwordField" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "login-form-password" },
            },
            [_vm._v("\n                Senha\n            ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "form-control required",
            class: { error: _vm.errors.password },
            attrs: {
              type: "password",
              id: "login-form-password",
              placeholder: "Digite sua senha",
              required: "",
              "aria-required": "true",
              "aria-describedby": "form-password-error",
              name: "loginPassword",
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "password-visibility-icon",
            attrs: {
              src: _vm.iconOpen,
              alt: "visibility-off icon",
              "data-visible": _vm.iconOpen,
              "data-notvisible": _vm.iconClosed,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.password,
                  expression: "errors.password",
                },
              ],
              staticClass: "invalid-feedback",
              attrs: { id: "form-password-error" },
            },
            [_vm._v(_vm._s(_vm.errors.password))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "pforgot-password" }, [
            _c(
              "a",
              {
                attrs: {
                  id: "password-reset",
                  title: "Esqueceu sua senha?",
                  "data-toggle": "modal",
                  "data-target": "#requestPasswordResetModal",
                },
                on: {
                  click: function ($event) {
                    return _vm.passwordReset()
                  },
                },
              },
              [
                _vm._v(
                  "\n                    Esqueceu sua senha?\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "login-option btn",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.login()
              },
            },
          },
          [_vm._v("Entrar")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }