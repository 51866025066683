var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quotationBody allStoresComponent" }, [
    _c(
      "div",
      { staticClass: "seller__detail" },
      [
        _vm.type === "multiseller"
          ? [
              _c(
                "div",
                { staticClass: "quotation-sellers-carousel" },
                _vm._l(_vm.quotation.sellers, function (seller, index) {
                  return _vm.quotation
                    ? _c("div", { staticClass: "seller__detail" }, [
                        seller.images
                          ? _c("img", {
                              attrs: {
                                loading: "lazy",
                                src: seller.images.url,
                                alt: seller.images.alt,
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ]
          : [
              _vm.quotation.images
                ? _c("img", {
                    attrs: {
                      loading: "lazy",
                      src: _vm.quotation.images.url,
                      alt: _vm.quotation.images.alt,
                    },
                  })
                : _c("img", {
                    attrs: { loading: "lazy", src: "teste", alt: "teste" },
                  }),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "quotationBody__col quotationBody__col__SellerName-mobile",
      },
      [
        _vm.type === "seller"
          ? [
              _c("h5", { staticClass: "d-none d-lg-flex" }, [
                _vm._v(_vm._s(_vm.quotation.name)),
              ]),
              _vm._v(" "),
              _vm.quotation.flag
                ? _c(
                    "div",
                    {
                      staticClass: "quotation__flag",
                      class: _vm.quotation.flag.id,
                    },
                    [
                      _c("img", {
                        attrs: {
                          loading: "lazy",
                          src: _vm.quotation.flag.image.url,
                          alt: _vm.quotation.flag.image.alt,
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.quotation.flag.name) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          : [
              _c("h5", { staticClass: "d-none d-lg-flex" }, [
                _vm._v("Múlti Lojas"),
              ]),
              _vm._v(" "),
              _vm.quotationModel.multisellers.flag
                ? _c(
                    "div",
                    {
                      staticClass: "quotation__flag",
                      class: _vm.quotationModel.multisellers.flag.id,
                    },
                    [
                      _c("img", {
                        attrs: {
                          loading: "lazy",
                          src: _vm.quotationModel.multisellers.flag.image.url,
                          alt: _vm.quotationModel.multisellers.flag.image.alt,
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.quotationModel.multisellers.flag.name) +
                            " "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "quotationBody__col" }, [
      _c(
        "div",
        { staticClass: "d-lg-none", staticStyle: { "padding-bottom": "8px" } },
        [
          _vm.type === "seller"
            ? _c("h5", [_vm._v(_vm._s(_vm.quotation.name))])
            : _c("h5", [_vm._v("Múlti Lojas")]),
        ]
      ),
      _vm._v(" "),
      _c("p", [_vm._v("Preço total")]),
      _vm._v(" "),
      _vm.type === "seller"
        ? _c("span", [
            _vm._v(_vm._s(_vm.quotation.totals.grandTotal.formatted)),
          ])
        : _c("span", [_vm._v(_vm._s(_vm.quotation.total.formatted))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "quotationBody__col" }, [
      _c(
        "p",
        { staticClass: "quotationBody__col_info" },
        [
          _vm._v("\n            Entrega\n            "),
          _c("tooltip", {
            attrs: {
              msg: "Prazo previsto para entrega a partir da confirmação do pedido pelo vendedor.",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.type === "seller"
        ? _c("span", [
            _vm._v(_vm._s(_vm.quotation.shippingDeliveryEstimateHour)),
          ])
        : _c("span", [_vm._v("Múltiplas Entregas")]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "quotationBody__col d-lg-none" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quotationBody__col d-none d-lg-flex" },
      [
        _vm.type === "seller"
          ? [
              _vm.quotation.items.totalQuantity ===
              _vm.quotationModel.totalBasketItems
                ? _c("p", { staticClass: "allProducts__shippment" }, [
                    _vm._v("Entrega todos os produtos"),
                  ])
                : _c("p", { staticClass: "allProducts__shippment" }, [
                    _vm._v(
                      "Entrega " +
                        _vm._s(_vm.quotation.items.totalQuantity) +
                        " de " +
                        _vm._s(_vm.quotationModel.totalBasketItems) +
                        " produtos "
                    ),
                  ]),
            ]
          : [
              _c("p", { staticClass: "allProducts__shippment" }, [
                _vm._v("Entrega todos os produtos"),
              ]),
            ],
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "seeDetails",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("handle:quotation")
              },
            },
          },
          [_vm._v("Ver detalhes")]
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quotationBody__col d-lg-none" },
      [
        _vm.type === "seller"
          ? [
              _vm.quotation.items.totalQuantity ===
              _vm.quotationModel.totalBasketItems
                ? _c("p", { staticClass: "allProducts__shippment" }, [
                    _vm._v("Entrega todos os produtos"),
                  ])
                : _c("p", { staticClass: "allProducts__shippment" }, [
                    _vm._v(
                      "Entrega " +
                        _vm._s(_vm.quotation.items.totalQuantity) +
                        " de " +
                        _vm._s(_vm.quotationModel.totalBasketItems) +
                        " produtos "
                    ),
                  ]),
            ]
          : [
              _c("p", { staticClass: "allProducts__shippment" }, [
                _vm._v("Entrega todos os produtos"),
              ]),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "quotationBody__col d-lg-none" }, [
      _c(
        "span",
        {
          staticClass: "seeDetails",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("handle:quotation")
            },
          },
        },
        [_vm._v("Ver detalhes")]
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cta__container" }, [
      _c("button", { staticClass: "btn quotation-btn" }, [_vm._v("Escolher")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }