var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quotationPage" },
    [
      _c(
        "h1",
        {
          staticClass:
            "container quotationPage-title d-flex flex-row justify-content-between align-items-center",
        },
        [
          _vm._v("\n         Escolha a melhor oferta\n         "),
          _c("quotation-share"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("quotation-all-store", {
            attrs: { quotationModel: _vm.quotationMethods },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("quotation-share-modal-info", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShareQuotation,
            expression: "isShareQuotation",
          },
        ],
      }),
      _vm._v(" "),
      _c("request-password-reset-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showResetForm,
            expression: "showResetForm",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }