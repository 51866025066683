<template>
    <div class="checkout-resume-box" >
        <div class="p-3 resumeBox">
            <div class="summaryBox" :class="{'fineshed': fineshed}">
                <div class="order-total-summary">
                    <h2>RESUMO DO PEDIDO</h2>
                    <order-total-summary></order-total-summary>
                </div>
                <div class="next-step-button">
                    <!-- cta back home -->
                    <a class="summary-back-home-btn" :href="homeUrl" v-show="showBack">Continuar Comprando</a>
                    <!-- CTA para continuar -->
                    <button v-show="showCta" class="continue-btn" @click="handleClick" :disabled="disabled">
                        {{ fineshed ? 'Finalizar Compra' : 'Continuar' }}
                        <i class="icon-chevron-right">
                    </i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import OrderTotalSummary from './checkout/OrderTotalSummary';
    import ListItem from './checkout/ListItem';
    import HandleStep from './HandleStep';
    import routes from '../../api/routes';

    export default {
        components: {
            OrderTotalSummary,
            HandleStep,
            ListItem
        },
        computed: {
            ...mapGetters('checkout', {
                paymentFinished: 'paymentFinished',
            }),
        },
        created() {
            this.$root.$refs.CheckoutReview = this;
        },
        data () {
            return {
                dataPath: routes.urlSite,
                fineshed: false,
                homeUrl: routes.home.show,
                disabled: false,
                showCta: true,
                showBack: false,
            }
        },
        props: ['activeStep'],
        methods: {
            validateStep(step){
                if (step <= this.activeStep) return true;
                else return false;
            },
            handlePlaceOrder(){
                this.$store.dispatch('checkout/placeOrder');
            },
            handleClick(){
                $(".continue-btn").addClass("is__loading");
                if(this.$root.$refs.Checkout.activeStep === 2){
                    this.$root.$refs.ShippingStep.handleClick()
                } else if(this.$root.$refs.Checkout.activeStep === 3) {
                    this.$root.$refs.PaymentStep.subimitPayment()
                }
                // else if(this.fineshed) {
                //     this.handlePlaceOrder()
                // }
            },
            setFineshed(val){
                this.fineshed = val
            }
        }
    }
</script>