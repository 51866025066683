var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quotationOptions" },
    [
      _vm.quotationModel && _vm.quotationModel.sellersWithAllProducts
        ? _vm._l(
            _vm.quotationModel.sellersWithAllProducts,
            function (quotation) {
              return _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "quotation-body__container",
                    class: {
                      selected: quotation.id === _vm.selectedQuotationId,
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.handleQuotationSelect(quotation)
                      },
                    },
                  },
                  [
                    _c("quotation-body", {
                      attrs: {
                        quotation: quotation,
                        quotationModel: _vm.quotationModel,
                        type: "seller",
                      },
                      on: {
                        "handle:quotation": function ($event) {
                          return _vm.toggleQuotationDetails(quotation, "seller")
                        },
                      },
                    }),
                    _vm._v(" "),
                    quotation.id === _vm.selectedQuotationId && _vm.showModal
                      ? _c("quotation-modal-details", {
                          attrs: {
                            quotationDetails: _vm.selectedQuotationDetails,
                            type: _vm.type,
                            showModal: _vm.showModal,
                          },
                          on: { closeModal: _vm.closeModal },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.quotationModel.multisellers && _vm.quotationModel.multisellers.sellers
        ? _vm._l(_vm.quotationModel.multisellers.sellers, function (quotation) {
            return _c("div", [
              _c(
                "div",
                {
                  staticClass: "quotation-body__container",
                  class: { selected: quotation.id === _vm.selectedQuotationId },
                  on: {
                    click: function ($event) {
                      return _vm.handleQuotationClick(quotation, _vm.event)
                    },
                  },
                },
                [
                  _c("quotation-body", {
                    attrs: {
                      quotation: quotation,
                      quotationModel: _vm.quotationModel,
                      type: "multiseller",
                    },
                    on: {
                      "handle:quotation": function ($event) {
                        return _vm.toggleQuotationDetails(
                          _vm.quotationModel.multisellers,
                          "multiseller"
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  quotation.id === _vm.selectedQuotationId && _vm.showModal
                    ? _c("quotation-modal-details", {
                        attrs: {
                          quotationDetails: _vm.selectedQuotationDetails,
                          type: _vm.type,
                          showModal: _vm.showModal,
                        },
                        on: { closeModal: _vm.closeModal },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.quotationModel && _vm.quotationModel.sellersWithoutAllProducts
        ? _vm._l(
            _vm.quotationModel.sellersWithoutAllProducts,
            function (quotation) {
              return _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "quotation-body__container",
                    class: {
                      selected: quotation.id === _vm.selectedQuotationId,
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.handleQuotationSelect(quotation)
                      },
                    },
                  },
                  [
                    _c("quotation-body", {
                      attrs: {
                        quotation: quotation,
                        quotationModel: _vm.quotationModel,
                        type: "seller",
                      },
                      on: {
                        "handle:quotation": function ($event) {
                          return _vm.toggleQuotationDetails(quotation, "seller")
                        },
                      },
                    }),
                    _vm._v(" "),
                    quotation.id === _vm.selectedQuotationId && _vm.showModal
                      ? _c("quotation-modal-details", {
                          attrs: {
                            quotationDetails: _vm.selectedQuotationDetails,
                            type: _vm.type,
                            showModal: _vm.showModal,
                          },
                          on: { closeModal: _vm.closeModal },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }