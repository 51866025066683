<template>
    <div class="appCheckout hide" id="checkout-main" :data-checkout-stage="activeStep===2 ? 'shipping' : activeStep===3 ? 'payment' : 'placeOrder'">
        <!-- stepper -->
        <stepper :activeStep="activeStep"></stepper>

        <handle-step v-if="activeStep!==1" @handle:click="backStep"/>

        <!-- step1 - cotacao -->
        <div v-if="activeStep==1">
            <quotation-step></quotation-step>
        </div>

        <!-- step2 - entrega | step3 - pagamento | step 4 - revisao -->
        <div v-else>
            <div class="checkoutColumns">
                <!-- step2 - entrega -->
                <shipping-step v-show="activeStep===2" :class="{current: activeStep===2}" @next:step="nextStep"></shipping-step>
                <!-- step3 - pagamento -->
                <payment-step v-show="activeStep===3" :class="{current: activeStep===3}" @back:step="backStep"></payment-step>
                <!-- step4 - revisao -->
                <review-step v-show="activeStep===4" :class="{current: activeStep===4}" @back:step="backStep"></review-step>
                <!-- review footer -->
                <checkout-review></checkout-review>
            </div>

            <div class="quotationSelected">
                <h2>DETALHES DO PEDIDO</h2>
                <quotation-selected></quotation-selected>
            </div>
        </div>

        <checkout-summary :enabled="true"></checkout-summary>

        <modal-message title="Erro ao finalizar seu pedido!" :message="errorMessage" :messageHtml="errorMessageHtml" @handle:click="handleClick" ></modal-message>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Stepper from '../components/Stepper';
    import QuotationStep from '../components/QuotationStep';
    import ShippingStep from '../components/ShippingStep';
    import PaymentStep from '../components/PaymentStep';
    import ReviewStep from '../components/ReviewStep';
    import CheckoutReview from '../components/CheckoutReview';
    import ModalMessage from '../components/ModalMessage'
    import CheckoutSummary from '../components/CheckoutSummary.vue';
    import HandleStep from '../components/HandleStep.vue';
    import QuotationSelected from '../components/quotation/QuotationSelected.vue'

    export default {
        props:['whatsnumber', 'whatslink', 'data', 'logged', 'closeurl'],
        components: {
            Stepper,
            QuotationStep,
            ShippingStep,
            PaymentStep,
            ReviewStep,
            CheckoutReview,
            ModalMessage,
            CheckoutSummary,
            HandleStep,
            QuotationSelected
        },
        data() {
            return {
                activeStep: 1,
            };
        },
        computed: {
            ...mapGetters('checkout', {
                changeStep: 'changeStep',
                errorMessage: 'errorMessage',
                errorMessageHtml: 'errorMessageHtml',
                selectedAddressIdModel: 'selectedAddressIdModel'
            }),
        },
        created() {
            this.$root.$refs.Checkout = this;
            $('body').on('click', '#buttonModal', function () {
                $('#modalMessage').modal('show');
            });
            this.$store.commit('account/setWhatslink', this.whatslink);
            this.$store.commit('account/setWhatsnumber', this.whatsnumber);
        },
        methods: {
            nextStep(){
                // console.log("🚀 ~ nextStep ~ nextStep:",  this.activeStep);
                this.activeStep = this.activeStep+1;
                //this.changeURL(this.activeStep)
                // console.log("🚀 ~ nextStep ~ this.activeStep:", this.activeStep);
            },
            backStep(){
                // console.log("🚀 ~ backStep ~ backStep:",  this.activeStep)
                this.activeStep = this.activeStep - 1;
                //this.changeURL(this.activeStep)
                // console.log("🚀 ~ backStep ~ this.activeStep:", this.activeStep)
            },
            goToStep(step){
                this.activeStep = step;
            },
            handleURL(url) {
                const urlObj = new URL(url);
                const step = parseInt(urlObj.searchParams.get('step'));
                if(step) this.activeStep = step
            },
            changeURL(step){
                const urlObj = new URL(window.location.href);
                urlObj.searchParams.set('step', step);
                const newUrl = urlObj.toString();
                window.history.pushState({}, '', newUrl);
            },
            handleClick(){
                //this.$root.$refs.Checkout.goToStep(2);
                //hide summary in mobile
                // $('.checkout-summary').fadeIn()
            }
        },
        mounted(){
            this.$store.dispatch('checkout/begin', JSON.parse(this.data));
            this.$store.commit('account/setLogged', this.logged === 'true' ? true : false)
            this.$store.commit('checkout/setCloseurl', this.closeurl)
            this.handleURL(window.location.href)
            $('.appCheckout').removeClass('hide');
            $('.checkout-skeleton').remove();
        },
        watch: {
            changeStep(step){
                if(step) {
                    this.activeStep=step;
                }
            },
            activeStep(step){
                // build carousel sellers
                if(step===1)$('body').trigger('sellerSlickCarousel');

                else {
                    //cta loading control
                    $(".continue-btn").removeClass("is__loading");
                    // control to shipping step
                    if(step===2 && this.$root.$refs.ShippingStep) {
                        this.$root.$refs.CheckoutSummary.showCta = true;
                        this.$root.$refs.CheckoutSummary.disabled = false;
                        this.$root.$refs.CheckoutReview.disabled = false;
                    }
                    // control to payment step
                    if(step===3) {
                        this.$root.$refs.PaymentStep.innerStep=1
                        this.$root.$refs.PaymentStep.populateBillingModel()
                    }
                    if(step===3 && this.$root.$refs.PaymentStep && this.$root.$refs.PaymentStep.innerStep===1) {
                        if(this.$root.$refs.CheckoutReview){
                            this.$root.$refs.CheckoutReview.disabled = false;
                            this.$root.$refs.CheckoutReview.fineshed = true;
                        }
                        if(this.$root.$refs.CheckoutSummary) {
                            this.$root.$refs.CheckoutSummary.disabled = false;
                            this.$root.$refs.CheckoutSummary.fineshed = true;
                        }
                    } else {
                        if(this.$root.$refs.CheckoutSummary) this.$root.$refs.CheckoutSummary.fineshed = false;
                        if(this.$root.$refs.CheckoutReview) this.$root.$refs.CheckoutReview.fineshed = false;
                    }
                }
            }
        },
    };
</script>