var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-tile-pd product-page-pdp col" }, [
    _c("div", { staticClass: "productTileBox" }, [
      _c("picture", [
        _c("div", { staticClass: "product-tile-image" }, [
          _c(
            "a",
            {
              staticClass: "product-tile-image-link",
              attrs: { href: _vm.product.selectedProductUrl },
            },
            [
              _c("img", {
                staticClass: "product-tile-component-image lazyload",
                attrs: {
                  loading: "lazy",
                  "data-src": _vm.product.images.small[0].url,
                  alt: _vm.product.productName,
                  title: _vm.product.images.small[0].title,
                  width: "120",
                  height: "120",
                  onerror: "this.src='" + _vm.urlNoImg + "'",
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "product-tile-body" }, [
        _c("div", { staticClass: "product-name-link" }, [
          _c("a", { attrs: { href: _vm.product.selectedProductUrl } }, [
            _vm._v(_vm._s(_vm.product.productName)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "component-price-buttom" },
          [
            _c(
              "div",
              { staticClass: "sz-text--small productTile__price--container" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "sz-text--smallest bold line-item-total-text unit-price-label",
                  },
                  [
                    _vm._v(
                      "\n                    A partir de\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "product-price" }, [
                  _c("span", { staticClass: "range" }, [
                    _c("div", { staticClass: "price-content-tile" }, [
                      _vm.product.promo && _vm.showBtn
                        ? _c("span", { staticClass: "price" }, [
                            _vm._v(
                              _vm._s(_vm.product.promo.promotionPriceFormatted)
                            ),
                          ])
                        : _c("span", { staticClass: "sales" }, [
                            _c("span", {
                              staticClass: "value d-none",
                              attrs: {
                                content: _vm.product.price.min
                                  ? _vm.product.price.min.sales.decimalPrice
                                  : _vm.product.price.sales.decimalPrice,
                              },
                            }),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.product.price.min
                                    ? _vm.product.price.min.sales.formatted
                                    : _vm.product.price.sales.formatted
                                ) +
                                "\n                            "
                            ),
                          ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("div", { staticClass: "price" }, [
                      _c(
                        "span",
                        { staticClass: "strike-through list" },
                        [
                          _vm.product.promo && _vm.showBtn
                            ? [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.product.promo.defaultPriceFormatted
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.product.promo
                      ? _c("span", { staticClass: "promotion_tag" }, [
                          _vm._v(
                            _vm._s(_vm.product.promo.promotionPercentage) +
                              "% OFF"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.showBtn === true
              ? [
                  _vm.showQnt === true
                    ? [
                        _c("product-quantity", {
                          attrs: {
                            id: _vm.product.id,
                            uuid: _vm.product.uuid,
                            quantity: _vm.product.quantity,
                            uuidLineItem: _vm.product.uuidLineItem,
                            productAvailable: _vm.product.available,
                            urlAddCart: _vm.urlAddCart,
                            urlUpdateQnt: _vm.urlUpdateQnt,
                            urlRemoveProduct: _vm.urlRemoveProduct,
                            use: "PDP",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showQnt === false
                    ? [
                        _c("product-add", {
                          attrs: {
                            id: _vm.product.id,
                            uuid: _vm.product.uuid,
                            quantity: _vm.product.quantity,
                            uuidLineItem: _vm.product.uuidLineItem,
                            productAvailable: _vm.product.available,
                            urlAddCart: _vm.urlAddCart,
                            use: "PDP",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }